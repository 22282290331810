import backgroundLogo from "../assets/images/login-page-img.png";
import logo from "../assets/images/logo.png";
import profileDefault from "../assets/images/photo1.png";

const image = {
  'backgroundLogo': backgroundLogo,
  'logo': logo,
  profileDefault: profileDefault
}

export default image;