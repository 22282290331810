import React, { useState, useContext } from "react";
import { InputGroup, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import AuthApi from "../../api/authApi";
import image from "../../util/image";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const api = new AuthApi();
  const navigate = useNavigate();
  const [loginData, setLoginData] = useState({
    email: "",
  });

  const [emailValidate, setIsEmailValidate] = useState("");
  const [loading, setLoading] = useState(false);
  const onChange = (e) => {
    let { name, value } = e.target;
    let d = { ...loginData };
    d[name] = value;
    setLoginData(d);
  };

  const checkEmailValidation = () => {
    let check = { ...loginData };
    let validating = check.email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    if (validating) {
      setIsEmailValidate("valid");
      return true;
    } else {
      setIsEmailValidate("notValid");
      return false;
    }
  };

  const submitData = () => {
    let EmailValidation = checkEmailValidation();

    if (EmailValidation) {
      setLoading(true);
      api
        .forgotPassword(loginData)
        .then((res) => {
          if (res.success) {
            setLoading(false);
            toast.success("Mail Send Successfully");
            setLoginData({ email: "" });
            navigate("/login");
            //console.info(res.data);
          } else {
            setLoading(false);
            toast.error(res.msg);
            setIsEmailValidate("notValid");
          }
        })
        .catch((err) => {
          setLoading(false);
          console.error("login error => ", err);
        });
    }
  };

  return (
    <>
      <div className="main">
        <div className="login-page">
          <div className="login-wrap d-flex align-items-center flex-wrap justify-content-center">
            <div className="container">
              <div className="row align-items-end">
                <div className="col-md-6 col-lg-6 col-xl-7 d-flex justify-content-center justify-content-sm-center justify-content-md-end justify-content-lg-end">
                  <div className="tagline">
                    <span className="liner"></span>

                    <h2 className="d-inline text-light text-wrap">
                      Manage feedback with our software.
                    </h2>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 col-xl-5">
                  <div className="login-box bg-white box-shadow border-radius-20">
                    <div className="login-title mb-0">
                      <div className="login-logo-container">
                        <Link to={'/login'}>
                          <img src={image.logo} className="login-logo" alt="Logo" />
                        </Link>
                      </div>
                      <div className="welcome-text">Forgot Password</div>
                      <div className="instruction-text">
                        Enter your email to get the link to reset new password.
                      </div>
                    </div>
                    <form action="dashboard">
                      <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            placeholder="Enter email"
                            aria-describedby="basic-addon1"
                            className="group-input-field"
                            onBlur={checkEmailValidation}
                            name="email"
                            onChange={onChange}
                          />
                          <InputGroup.Text
                            id="basic-addon1"
                            className="group-input-button input-group-text"
                          >
                            {emailValidate == "valid" ? (
                              <i
                                className="icon-copy fa fa-check-circle customization"
                                aria-hidden="true"
                              ></i>
                            ) : (
                              ""
                            )}
                            {emailValidate == "notValid" ? (
                              <i
                                className="icon-copy fa fa-close customization"
                                style={{ color: "red" }}
                                aria-hidden="true"
                              ></i>
                            ) : (
                              ""
                            )}
                          </InputGroup.Text>
                        </InputGroup>
                      </div>
                      <div className="form-group">
                        <button
                          type="button"
                          className="btn btn-primary btn-block"
                          onClick={submitData}
                        >
                          {loading ? <> <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                            Loading...</> : "Send"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
