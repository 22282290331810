import { useState, useEffect, useRef, useCallback } from 'react';
import text from '../../util/text';

const EditableCell = ({ getValue, table, row, column }) => {
  const initialValue = getValue();
  const [value, setValue] = useState(initialValue);
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef(null);
  const cellRef = useRef(null);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const saveValue = useCallback(() => {
    if (value !== initialValue) {
      table.options.meta.updateData(row.index, column.id, value, row.original._id);
    }
    setIsEditing(false);
  }, [value, initialValue, table, row.index, column.id, row.original._id]);

  const onBlur = useCallback(() => {
    saveValue();
  }, [saveValue]);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isEditing && cellRef.current && !cellRef.current.contains(event.target)) {
        onBlur();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEditing, onBlur]);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      saveValue();
    }
  };

  return (
    <div ref={cellRef} className="editable-cell d-flex justify-content-center" onClick={handleDoubleClick}>
      {isEditing ? (
        <input
          value={value}
          onChange={onChange}
          className="form-control editable-input"
          onBlur={onBlur}
          onKeyDown={handleKeyDown}
          ref={inputRef}
        />
      ) : (
        <span>
          {value ? value :  <span className=''>&nbsp;</span>}
         {/*  {value ? value :  <span className='tobeFilled'>{text.toBeFilled}</span>} */}
        </span>
      )}
    </div>
  );
};

export default EditableCell;