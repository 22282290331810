import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage, getToken } from "firebase/messaging";
import AuthApi from "./api/authApi"; // Adjust the import path according to your project structure

// Replace this firebaseConfig object with the configurations for the project you created on your firebase console. 
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
};

initializeApp(firebaseConfig);

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

//const messaging = getMessaging();
const api = new AuthApi();


// Request permission for notifications
export const requestForToken = async () => {
    try {
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
            const currentToken = await getToken(messaging, {
                vapidKey: process.env.REACT_APP_FIREBASE_APP_VAPIDKEY
            });
            if (currentToken) {
                console.log('current token for client: ', currentToken);
                // Store the token in localStorage
                //localStorage.setItem('firebaseToken', currentToken);
                return currentToken
            } else {
                console.log('No registration token available. Request permission to generate one.');
            }
        } else if (permission === 'denied') {
            console.warn('Notifications are denied by the user.');
        }
    } catch (error) {
        console.error('An error occurred while retrieving token.', error);
    }
};

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            console.log("payload", payload);
            resolve(payload);
        });
});