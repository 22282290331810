import BaseApi from "./baseApi";

class FeedbackApi extends BaseApi {
  async addEditFeedback(data) {
    return await this.API({
      url: "/feedback/addFeedback",
      data: data,
      method: "post",
    });
  }

  async getFeedbacks(params = {}) {
    const queryParams = new URLSearchParams(params).toString();
    const queryString = queryParams ? `?${queryParams}` : "";
    return await this.API({
      url: `/feedback/getFeedbacks${queryString}`,
      method: "get",
    });
  }

  async getFeedbackById(data) {
    return await this.API({
      url: "/feedback/getFeedbackById",
      data: data,
      method: "post",
    });
  }
  async deactivateFeedback(data) {
    return await this.API({
      url: "/feedback/deactivateFeedback",
      data: data,
      method: "post",
    });
  }
  async getColleagueFeedbacks(params = {}) {
    // Construct query string from the params object
    const queryParams = new URLSearchParams(params).toString();
    const queryString = queryParams ? `?${queryParams}` : "";
    return await this.API({
      url: `/feedback/getColleagueFeedbacks${queryString}`,
      method: "get",
    });
  }

  async getColleagueList(userId) {
		return await this.API({
			url: `/feedback/getTeamMembers/${userId}`,
			method: "get"
		});
	}

  async getAllColleagueList(userId) {
		return await this.API({
			url: `/feedback/getAllTeamMembers/${userId}`,
			method: "get"
		});
	}

  async assFeedbackToColleague(data) {
    return await this.API({
      url: "/feedback/assgneFeedbackToColleague",
      data: data,
      method: "post",
    });
  }

  async deactivateColleagueFb(data) {
    return await this.API({
      url: "/feedback/deactivateColleagueFeedback",
      data: data,
      method: "post",
    });
  }
  
  async addEditCollegueFeedback(data) {
    return await this.API({
      url: "/feedback/addCollegueFeedback",
      data: data,
      method: "post",
    });
  }
}

export default FeedbackApi;
