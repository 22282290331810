import React from 'react'

export const Loader = () => {
  return (
    <div className='d-flex justify-content-center align-items-center p-5'>
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  )
}
