import React from "react";
import { Route, Routes, Navigate } from 'react-router-dom';
import Dashboard from "../pages/master/dashboard";
import Profile from "../pages/master/profile";
import AddManager from "../pages/master/ManageTeamMember/AddTeamMember";
import ManageAxeTool from "../pages/master/ManageAxeTool/index";
import ManageFeedbacks from "../pages/master/ManageFeedbacks";
import ManageColleagueFeedbacks from "../pages/master/ManageColleagueFeedbacks";
import ManageTeamMember from "../pages/master/ManageTeamMember";
import Header from "../layouts/header";
import AutoLogout from "../pages/auth/logout";
import ChangePassword from "../pages/master/ChangePassword"
import AddTeam from "../pages/master/ManageTeamMember/AddTeam";

const BankAdminRouter = () => {
  return (
    <>
      <div className="main">
        <Header />
        <div className="main-container" style={{ height: 'inherit' }}>
          <div className="pd-ltr-20 xs-pd-20-10" style={{ height: '100%', overflow: 'auto' }}>
            <Routes>
              <Route path="/dashboard" element={<Dashboard />} />

              {/* Manage Axe Tool */}
              <Route path="/manageAxeTool" element={<ManageAxeTool />} />

              {/* magage Colleague’s feedback */}
              <Route path="/manage-colleague-feedback" element={<ManageColleagueFeedbacks />} />
              <Route path="/manage-colleague-feedback/primary" element={<ManageColleagueFeedbacks />} />
              <Route path="/manage-colleague-feedback/axes" element={<ManageColleagueFeedbacks />} />
              <Route path="/manage-colleague-feedback/general" element={<ManageColleagueFeedbacks />} />

              {/* manage feedback */}
              <Route path="/manageFeedback" element={<ManageFeedbacks />} />
              <Route path="/manageFeedback/primary" element={<ManageFeedbacks />} />
              <Route path="/manageFeedback/axes" element={<ManageFeedbacks />} />
              <Route path="/manageFeedback/general" element={<ManageFeedbacks />} />

              {/* Manager TeamMember */}
              <Route path="/addManager/:id" element={<AddManager />} />
              <Route path="/addManager" element={<AddManager />} />
              <Route path="/manageTeamMember/:bankId" element={<ManageTeamMember />} />
              <Route path="/assignTeamMember" element={<AddTeam />} /> 

              {/* manage profile */}
              <Route path="/profile" element={<Profile />} />

              {/* change password */}
              <Route path="/changePassword" element={<ChangePassword />} />

              {/* logout */}
              <Route path="/logout" element={<AutoLogout />} />
              <Route path="/*" element={<Navigate to={`/manageAxeTool`} />} />
            </Routes>
          </div>
        </div>

      </div>
    </>
  )
}
export default BankAdminRouter;
