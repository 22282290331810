import React from "react";
import {Route, Routes, Navigate} from 'react-router-dom';
import Login from "../pages/auth/login";
import ForgotPassword from "../pages/auth/forgotPassword";
import ResetPassword from "../pages/auth/resetPassword";

const AuthRouter = () => {
    return(
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/*" element={<Navigate to={`/login`}/>} />
        </Routes>
    )

}

export default AuthRouter;