import { useState, useEffect, useCallback } from 'react';
import text from '../../util/text';

const DropdownCell = ({ getValue, table, row, column }) => {
  const initialValue = getValue();
  const { updateData } = table.options.meta;

  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    const newValue = e.target.value;
    // console.log('after change:', newValue);
    setValue(newValue);
    updateData(row.index, column.id, newValue, row.original._id);
  };

  return (
    <div className="d-flex justify-content-center">
      <select
        value={value || ""}
        className="custom-select mr-2"
        onChange={handleChange}
      >
        <option value="" disabled>Select</option>
        <option value="weBuy">{text.webuy}</option>
        <option value="weSell">{text.wesell}</option>
        <option value="clientBuys">{text.clientBuys}</option>
        <option value="clientSells">{text.clientSells}</option>
      </select>
    </div>
  );
};

export default DropdownCell;