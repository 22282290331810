import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BankApi from "../../../api/bankApi";
import TeamMemberApi from "../../../api/memberApi";
import { toast } from "react-toastify";
import text from "../../../util/text";
import { Cookies } from "react-cookie";

const AddTeamMember = () => {
  const { id } = useParams();
  const location = useLocation();
  const api = new BankApi();
  const teamManagerApi = new TeamMemberApi();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    _id: '',
    name: "",
    bankId: "",
    role: "",
    email: "",
    phone: "",
    isManager: false,
    teamName: ""
  });
  const [banks, setBanks] = useState([]);
  const [errors, setErrors] = useState({});
  const cookies = new Cookies();
  let cookieData = cookies.get('userInfo');
  const [submitBtnStatus, setSubmitBtnStatus] = useState(false);

  useEffect(() => {
    api.getBanks().then((res) => {
      if (res.success) {
        if ((cookieData.userRole == 'trader' || cookieData.userRole == 'sales' || cookieData.userRole == 'syndicate') && cookieData.isManager) {
          let selectedBank = res.data.filter(item => item._id == cookieData.AssociatedWith && item.status != 'deactive');
          setBanks(selectedBank);
          let role;
          if (cookieData.userRole == 'trader') {
            role = 'trader';
          } else if (cookieData.userRole == 'sales') {
            role = 'sales';
          } else {
            role = 'syndicate';
          }
          if (selectedBank.length > 0) {
            setFormData(prevData => ({
              ...prevData,
              bankId: selectedBank[0]._id,
              role: role
            }));
          }
        } else {
          let selectedBank = res.data.filter(item => item.status != 'deactive');
          setBanks(selectedBank);
          //setBanks(res.data);
          if (res.data.length > 0) {
            let bankId;
            if (cookieData.userRole == 'bankAdmin') {
              bankId = res?.data.find(item => item._id == cookieData.AssociatedWith);
            } else {
              bankId = res.data[0]._id;
            }
            setFormData(prevData => ({ ...prevData, bankId: bankId._id }));
          }
        }
      } else {
        toast.error(res.message);
      }
    }).catch((err) => {
      console.error("Fetch all banks error => ", err);
    });

    if (id) {
      teamManagerApi.getTeamManagerById({ id: id }).then((res) => {
        if (res.success) {
          const { _id, name, AssociatedWith, userRole, email, phone, isManager, teamName } = res.data;
          setFormData((prevData) => ({
            ...prevData,
            _id: _id,
            bankId: AssociatedWith,
            role: userRole,
            name: name,
            email: email,
            phone: phone,
            isManager: isManager,
            teamName: teamName || "" // Set existing team name
          }));
        } else {
          toast.error(res.message);
        }
      }).catch((err) => {
        console.error("Fetch team member by ID error => ", err);
      });
    }
  }, [id]);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const fieldValue = type === "checkbox" ? checked : value;
    setFormData({ ...formData, [name]: fieldValue });
    const newErrors = { ...errors };

    if (type !== "checkbox" && fieldValue.trim() !== "") {
      delete newErrors[name];
    }

    if (name === "email" && fieldValue.trim() !== "") {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(fieldValue)) {
        newErrors.email = "Invalid email address";
      } else {
        delete newErrors.email;
      }
    }

    // if (name === "phone" && value.trim() !== "") {
    //   if (value.length !== 10) {
    //     newErrors.phone = "Phone number must be exactly 10 digits";
    //   } else {
    //     delete newErrors.phone;
    //   }
    // }
    setErrors(newErrors);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newErrors = {};
    if (!formData.name) {
      newErrors.name = "Name is required";
    }
    if (!formData.bankId) {
      newErrors.bankId = "Bank is required";
    }
    if (!formData.role) {
      newErrors.role = "Role is required";
    }
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)
    ) {
      newErrors.email = "Invalid email address";
    }
    // if (!formData.phone) {
    //   newErrors.phone = "Phone number is required";
    // } else if (formData.phone.length !== 10) {
    //   newErrors.phone = "Phone number must be exactly 10 digits";
    // }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setSubmitBtnStatus(true);
      let sendObj = { ...formData };
      if ((cookieData.userRole == 'trader' || cookieData.userRole == 'sales' || cookieData.userRole == 'syndicate') && cookieData.isManager) {
        let role;
        if (cookieData.userRole == 'trader') {
          role = 'trader';
        } else if (cookieData.userRole == 'sales') {
          role = 'sales';
        } else {
          role = 'syndicate';
        }
        sendObj.addedBy = role;
        sendObj.underMangerId = cookieData._id;
      }
      teamManagerApi.addEditTeamMember(sendObj).then((res) => {
        if (res.success) {
          toast.success(res.message);
          navigate(-1);
          setSubmitBtnStatus(false);
        } else {
          toast.error(res.message);
          setErrors({});
          setSubmitBtnStatus(false);
        }
      }).catch((err) => {
        console.error("Add team manager error => ", err);
        setErrors({});
        setSubmitBtnStatus(false);
      });
    }
  };

  return (
    <>
      <div className="min-height-200px">
        <div className="pd-20 d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
          <h5 className="text-black mb-3 mb-md-0">{text.addUpdateTeamHeader}</h5>
          <span className="text-black mb-3 mb-md-0 cursor" onClick={() => navigate(-1)}>
            <span className="icon-copy ti-angle-left" />
            {text.backText}
          </span>
        </div>
        <div className="card-box mb-30">
          <div className="pd-20 d-flex" >
            <div className="container">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="bankId">Bank</label>
                      <select
                        className="form-control"
                        id="bankId"
                        name="bankId"
                        value={formData.bankId}
                        onChange={handleChange}
                        disabled={((cookieData.userRole == 'trader' || cookieData.userRole == 'sales' || cookieData.userRole == 'syndicate') && cookieData.isManager) || cookieData.userRole == 'bankAdmin'}
                      >
                        <option value="">Select Bank</option>
                        {banks.map((bank) => (
                          <option key={bank._id} value={bank._id}>
                            {bank.bankName}
                          </option>
                        ))}
                      </select>
                      {errors.bankId && (
                        <div className="text-danger">
                          {errors.bankId}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="name">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder={text.placeHolderName}
                      />
                      {errors.name && (
                        <div className="text-danger">
                          {errors.name}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="phone">Phone</label>
                      <input
                        type="tel"
                        className="form-control"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        onInput={(e) => e.target.value = e.target.value.replace(/\D/, '')}
                        placeholder={text.phone}
                      // minLength={10}
                      // maxLength={10}
                      />
                      {errors.phone && (
                        <div className="text-danger">
                          {errors.phone}
                        </div>
                      )}
                    </div>
                    {(cookieData.userRole !== "trader" && cookieData.userRole !== "sales" && cookieData.userRole !== "syndicate") && (
                      <div className="form-check d-flex align-items-center">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="isManager"
                          name="isManager"
                          checked={formData.isManager}
                          onChange={handleChange}
                          value={formData.isManager}
                        />
                        <label className="form-check-label" htmlFor="isManager">
                          Manager
                        </label>
                      </div>
                    )}
                    {/* Conditionally render team name input */}
                    {formData.isManager && (
                      <div className="form-group">
                        <label htmlFor="teamName">Team Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="teamName"
                          name="teamName"
                          value={formData.teamName}
                          onChange={handleChange}
                          placeholder={text.teamName}
                        />
                        {errors.teamName && (
                          <div className="text-danger">
                            {errors.teamName}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="role">Role</label>
                      <select
                        className="form-control"
                        id="role"
                        name="role"
                        value={formData.role}
                        onChange={handleChange}
                        disabled={(cookieData.userRole == 'trader' || cookieData.userRole == 'sales' || cookieData.userRole == 'syndicate') && cookieData.isManager}
                      >
                        <option value="">Select Role</option>
                        <option value="syndicate">Syndicate</option>
                        <option value="sales">Sales</option>
                        <option value="trader">Trader</option>
                      </select>
                      {errors.role && (
                        <div className="text-danger">
                          {errors.role}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder={text.email}
                      />
                      {errors.email && (
                        <div className="text-danger">
                          {errors.email}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-secondary mr-4"
                    onClick={() => navigate(-1)}
                  >
                    {text.cancel}
                  </button>
                  <button type="submit" className="btn btn-orange" disabled={submitBtnStatus}>
                    {id && id !== '' ? text.updateManager : text.addManager}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTeamMember;