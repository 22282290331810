import React, { useState, useContext } from "react";
import { InputGroup, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AuthApi from "../../api/authApi";
import image from "../../util/image";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  /*  const { token } = useParams(); // Get the token from the URL */
  const api = new AuthApi();
  const [loginData, setLoginData] = useState({
    password: "",
    confirmPassword: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const onChange = (e) => {
    const { name, value } = e.target;
    setLoginData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validatePasswords = () => {
    const { password, confirmPassword } = loginData;
    if (password === "" || confirmPassword === "") {
      toast.error("Both Password Fields Must Be Filled Out.");
      return false;
    }
    if (password !== confirmPassword) {
      toast.error("Passwords Do Not Match.");
      return false;
    }
    return true;
  };

  const submitData = () => {
    if (validatePasswords()) {
      api
        .resetPassword(token, loginData.password, loginData.confirmPassword)
        .then((res) => {
          if (res.success) {
            toast.success("Password Reset Successfully");
            navigate("/login");
          } else {
            toast.error(res.msg);
          }
        })
        .catch((err) => {
          console.error("reset password error => ", err);
          toast.error("An Error Occurred While Resetting Password");
        });
    }
  };

  return (
    <>
      <div className="main">
        <div className="login-page">
          <div className="login-wrap d-flex align-items-center flex-wrap justify-content-center">
            <div className="container">
              <div className="row align-items-end">
                <div className="col-md-6 col-lg-6 col-xl-7 d-flex justify-content-center justify-content-sm-center justify-content-md-end justify-content-lg-end">
                  <div className="tagline">
                    <span className="liner"></span>

                    <h2 className="d-inline text-light text-wrap">
                      Manage feedback with our software.
                    </h2>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 col-xl-5">
                  <div className="login-box bg-white box-shadow border-radius-20">
                    <div className="login-title mb-0">
                      <div className="login-logo-container">
                        <Link to={'/login'}>
                          <img src={image.logo} className="login-logo" alt="Logo" />
                        </Link>
                      </div>
                      <div className="welcome-text">Reset Password</div>
                      <div className="instruction-text">
                        Enter New Password
                      </div>
                    </div>
                    <form action="dashboard">
                      <div className="form-group">
                        <label htmlFor="password">New Password</label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            type={!showPassword ? "password" : "text"}
                            placeholder="Password"
                            aria-describedby="basic-addon12"
                            className="group-input-field"
                            name="password"
                            onChange={onChange}
                          />
                          <InputGroup.Text
                            id="basic-addon1"
                            className="group-input-button"
                          >
                            {!showPassword ? (
                              <i
                                className="icon-copy fa fa-eye customization2"
                                onClick={() => setShowPassword(true)}
                                aria-hidden="true"
                              />
                            ) : (
                              <i
                                className="icon-copy fa fa-eye-slash customization2"
                                onClick={() => setShowPassword(false)}
                                aria-hidden="true"
                              />
                            )}
                          </InputGroup.Text>
                        </InputGroup>
                      </div>
                      <div className="form-group">
                        <label htmlFor="confirmPassword">
                          Confirm Password
                        </label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            type={!showConfirmPassword ? "password" : "text"}
                            placeholder="Confirm Password"
                            aria-describedby="basic-addon12"
                            className="group-input-field"
                            name="confirmPassword"
                            onChange={onChange}
                          />
                          <InputGroup.Text
                            id="basic-addon1"
                            className="group-input-button"
                          >
                            {!showConfirmPassword ? (
                              <i
                                className="icon-copy fa fa-eye customization2"
                                onClick={() => setShowConfirmPassword(true)}
                                aria-hidden="true"
                              />
                            ) : (
                              <i
                                className="icon-copy fa fa-eye-slash customization2"
                                onClick={() => setShowConfirmPassword(false)}
                                aria-hidden="true"
                              />
                            )}
                          </InputGroup.Text>
                        </InputGroup>
                      </div>
                      <div className="form-group">
                        <button
                          type="button"
                          className="btn btn-primary btn-block"
                          onClick={submitData}
                        >
                          Reset Password
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
