import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import {CookiesProvider} from 'react-cookie'
import { ToastContainer } from "react-toastify";
import App from './App';
import reportWebVitals from './reportWebVitals';
import "react-toastify/dist/ReactToastify.css";
import "./assets/styles/core.css";
import "./assets/styles/icon-font.min.css";
import "./assets/styles/style.css";
import "./assets/styles/custom.css";

const root = ReactDOM.createRoot(document.getElementById('root'));

const Index = () => {
  return (
    <BrowserRouter history={createBrowserHistory()}>
    <CookiesProvider>
      <App />
      <ToastContainer position="top-right" autoClose={2000} />
    </CookiesProvider>
  </BrowserRouter>
  )
}

root.render(
  // <React.StrictMode>
    <Index />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
