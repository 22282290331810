import BaseApi from "./baseApi";

class TeamMemberApi extends BaseApi {
  async addEditTeamMember(data) {
    return await this.API({
      url: "/teammember/addMember",
      data: data,
      method: "post",
    });
  }

  async getTeamMembers(bankId) {
    return await this.API({
      url: "/teammember/getTeamMembers",
      data: bankId,
      method: "post",
    });
  }

  async getTeamManagerById(data) {
    return await this.API({
      url: "/teammember/getTeamManagerById",
      data: data,
      method: "post",
    });
  }

  async deleteTeamManager(data) {
    return await this.API({
      url: "/teammember/deleteTeamManager",
      data: data,
      method: "post",
    });
  }

  async activatDeactivateTeamManager(data) {
    return await this.API({
      url: "/teammember/activatDeactivateTeamManager",
      data: data,
      method: "post",
    });
  }

  async getUsersWithoutManager(data) {
    return await this.API({
      url: "/teammember/usersWithoutManager",
      data: data,
      method: "post",
    });
  }

  async getRoleRelatedManagers(data) {
    return await this.API({
      url: "/teammember/getManagers",
      data: data,
      method: "post",
    });
  }

  async saveTeamMembers(data) {
    return await this.API({
      url: "/teammember/saveTeamMembers",
      data: data,
      method: "post",
    });
  }
}

export default TeamMemberApi;
