import React, { useContext, useMemo, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import DataTableEditable from "../../../components/DataTable/DataTableEditable";
import { useEffect } from "react";
//import BankApi from "../../../api/bankApi";
import FeedbackApi from "../../../api/feedbackApi";
import { toast } from "react-toastify";
import { DeleteModal } from "../../../components/Modal/DeleteModal";
import text from "../../../util/text";
import { capitalizeFirstLetter } from "../../../util/constants";
import { Loader } from "../../../components/Loader";
import IndeterminateCheckbox from "../../../components/DataTable/IndeterminateCheckbox";
import DropdownCell from "../../../components/DataTable/DropdownCell";
import GlobalContext from "../../../context/global/globalContext";
import { timeZoneChange, formatDate, sidebarModule } from "../../../util/constants";
import { utils, writeFile } from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Cookies } from "react-cookie";
import TextAreaCell from "../../../components/DataTable/TextAreaCell";
import { Popover, Overlay } from "react-bootstrap";

const Index = () => {
  const globalContext = useContext(GlobalContext);
  const api = new FeedbackApi();
  const navigate = useNavigate();
  const location = useLocation();
  const [feedbacks, setFeedbacks] = useState([]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [tableInstance, setTableInstance] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [type, setType] = useState(null);
  const [errors, setErrors] = useState({});
  const cookies = new Cookies();
  let cookieData = cookies.get("userInfo");
  const [isLoading, setIsLoading] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const [target, setTarget] = useState(null);
  const popoverRef = useRef(null);
  const [checkboxState, setCheckboxState] = useState({});

  // Update lastSegment based on the URL path segments
  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    const newLastSegment = pathSegments[pathSegments.length - 1];
    setType(newLastSegment);
  }, [location.pathname]);

  // Fetch feedbacks whenever lastSegment changes
  useEffect(() => {
    if (type) {
      getColleagueFeedback(type);
    }
  }, [type]);

  useEffect(() => {
    if (showPopover) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPopover]);

  useEffect(() => {
    // Initialize column visibility state with default visibility set to true
    const initialVisibility = bankColumns.reduce((acc, column) => {
      acc[column.id] = true;
      return acc;
    }, {});
    setColumnVisibility(initialVisibility);
    // Set checkbox state to reflect initial visibility
    setCheckboxState(initialVisibility);
  }, []);

  // to persists checkbox changes in filters when users log in/log out
  useEffect(() => {
    const storedVisibility = JSON.parse(localStorage.getItem('columnVisibility'));
    let data;
    if (storedVisibility && storedVisibility.collegueFeedback) {
      if (type == 'primary') {
        data = storedVisibility.collegueFeedback['primary']
      } else if (type == 'axes') {
        data = storedVisibility.collegueFeedback['axes']
      } else if (type == 'general') {
        data = storedVisibility.collegueFeedback['general']
      } else {
        data = storedVisibility.collegueFeedback['all']
      }
      setColumnVisibility(data);
      setCheckboxState(data);
    } else {
      initializeColumnVisibility();
    }
  }, [type]);

  const getColleagueFeedback = async (feedbackType) => {
    try {
      setIsLoading(true)
      let sendObj = {
        feedbackType: feedbackType,
        associatedWith: cookieData.AssociatedWith,
        userId: cookieData._id,
      };
      const res = await api.getColleagueFeedbacks(sendObj);
      if (res.success) {
        setFeedbacks(res.data);
        setIsLoading(false)
      }
    } catch (err) {
      console.error("Getting feedbacks error => ", err);
      setIsLoading(false)
    }
  };

  const data = useMemo(() => feedbacks, [feedbacks]);

  const feedbackTypeMap = {
    Axes: 'A',
    Primary: 'P',
    General: 'G'
  };

  const bankColumns = [
    {
      id: "select",
      header: ({ table }) => (
        <span className="px-1">
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        </span>
      ),
      cell: ({ row }) => (
        <span className="px-1">
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </span>
      ),
      size: 60
    },
    {
      header: "Copy",
      id: "copy",
      accessorKey: "copy",
      cell: ({ row }) => (
        <div className="px-1">
          <span className="px-4" style={{ cursor: "copy" }}>
            <i
              className="icon-copy fa fa-clone"
              aria-hidden="true"
              onClick={() => copyRowValue(row)}
            ></i>
          </span>
        </div>
      ),
      size: 80,
    },
    {
      header: "Type",
      accessorKey: "feedbackType",
      id: "feedbackType",
      size: 70,
      cell: ({ row }) => {
        const feedbackType = row.original.feedbackType;
        const displayValue = feedbackTypeMap[feedbackType] || feedbackType;
        return <span className="d-flex justify-content-center">{displayValue}</span>;
      },
    },
    {
      header: "Title",
      accessorKey: "title",
      id: "title",
    },
    {
      header: "Client",
      accessorKey: "client",
      id: "client",
    },
    {
      header: "Bond",
      accessorKey: "bond",
      id: "bond",
    },
    {
      header: "Sector",
      accessorKey: "sector",
      id: "sector",
    },
    {
      header: "Side",
      accessorKey: "direction",
      cell: (props) => {
        return <DropdownCell {...props} />;
      },
      enableSorting: false,
      enableColumnFilter: true,
      id: "direction",
    },
    {
      header: "Interest",
      accessorKey: "interest",
      id: "interest",
    },
    {
      header: "Client's Target Level",
      accessorKey: "clientsTargetLevel",
      id: "clientsTargetLevel",
      size: 200,
    },
    {
      header: "Client's Amount",
      accessorKey: "clientsAmount",
      id: "clientsAmount",
      size: 200,
    },
    {
      header: "Comments",
      accessorKey: "comments",
      cell: (props) => {
        return <TextAreaCell {...props} />
      },
      id: "comments",
      size: 180,
    },
    {
      header: "Created By",
      accessorKey: "createdByUserName",
      id: 'createdByUserName',
      cell: ({ row }) => (
        <>
          <div className="d-flex justify-content-left">
            {row.original.createdByUserName}
          </div>
          <div>({row.original.teamName})</div>
        </>

      ),
      width: 180,
    },
    {
      header: "Shared on",
      accessorKey: "createdDate",
      id: 'createdDate',
      cell: ({ row }) => (
        <div className="d-flex justify-content-left">
          {timeZoneChange(row.original.createdDate, globalContext.timeZoneValue)}
        </div>
      ),
      width: 180,
    },
    {
      header: "Created On",
      accessorKey: "createdAt",
      id: 'createdAt',
      cell: ({ row }) => (
        <div className="d-flex justify-content-left">
          {timeZoneChange(row.original.createdAt, globalContext.timeZoneValue)}
        </div>
      ),
      width: 180,
    },
    {
      header: "",
      id: "action",
      cell: ({ row }) => (
        <span className='d-flex justify-content-center'>
          <div className="circle cursor" data-toggle="modal" data-target="#alert-modal"
            onClick={() => {
              setShowDeleteModal(true);
              setDeleteId(row.original);
            }}>
            <i className="icon-copy fa fa-trash plus-icon"></i>
          </div>
        </span>
      ),
    },
  ];

  /* const handleEdit = (row) => {
    navigate(`/addFeedback/${row._id}`);
  }; */

  const handleDelete = () => {
    api
      .deactivateColleagueFb({ feedbackId: deleteId._id, userId: cookieData._id })
      .then((res) => {
        if (res.success) {
          toast.success(res.message);
          getColleagueFeedback(capitalizeFirstLetter(type));
          setShowDeleteModal(false);
          setDeleteId(null);
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        setShowDeleteModal(false);
        setDeleteId(null);
        toast.error(err.message);
        console.error("Deleting feedback error => ", err);
      });
  };

  const handlePopoverClick = (event) => {
    setTarget(event.target);
    setShowPopover(!showPopover);
  };

  const handleClickOutside = (event) => {
    if (popoverRef.current && !popoverRef.current.contains(event.target)) {
      setShowPopover(false);
    }
  };

  const initializeColumnVisibility = () => {
    const initialVisibility = bankColumns.reduce((acc, column) => {
      acc[column.id] = true;
      return acc;
    }, {});
    setColumnVisibility(initialVisibility);
    setCheckboxState(initialVisibility);
    try {
      let key = sidebarModule[3]
      let data = {
        ...JSON.parse(localStorage.getItem('columnVisibility')),
        [key]: {
          all: {},
          primary: {},
          general: {},
          axes: {},
        }
      }

      // if (type == 'primary') {
      data[key]['primary'] = initialVisibility
      // } else if (type == 'axes') {
      data[key]['axes'] = initialVisibility
      // } else if (type == 'general') {
      data[key]['general'] = initialVisibility
      // } else {
      data[key]['all'] = initialVisibility
      // }
      const serializedVisibility = JSON.stringify(data);
      localStorage.setItem('columnVisibility', serializedVisibility);
    } catch (error) {
      console.error('Error serializing column visibility:', error);
    }
  };

  const combinedOnChangeHandler = (column) => (event) => {
    column.getToggleVisibilityHandler()(event);
    setCheckboxState((prevState) => {
      const newState = {
        ...prevState,
        [column.id]: event.target.checked,
      };
      let key = sidebarModule[3]
      let data = JSON.parse(localStorage.getItem('columnVisibility')) || {
        [key]: {
          all: {},
          primary: {},
          general: {},
          axes: {},
        },
      };

      if (type === 'primary') {
        data[key]['primary'][column.id] = event.target.checked;
      } else if (type === 'axes') {
        data[key]['axes'][column.id] = event.target.checked;
      } else if (type === 'general') {
        data[key]['general'][column.id] = event.target.checked;
      } else {
        data[key]['all'][column.id] = event.target.checked;
      }
      localStorage.setItem('columnVisibility', JSON.stringify(data));
      return newState;
    });
  };

  const columns = useMemo(() => bankColumns, []);

  const updateData = (rowIndex, columnId, value, id) => {
    const updatedRow = { ...feedbacks[rowIndex], [columnId]: value };
    const formData = { ...updatedRow, columnId, value, id }; // Adjust according to your API requirements

    api
      .addEditCollegueFeedback(formData)
      .then((res) => {
        if (res.success) {
          toast.success(res.message);
          setFeedbacks((old) =>
            old.map((row, index) => {
              if (index === rowIndex) {
                return updatedRow;
              }
              return row;
            })
          );
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        console.error("Add bank error => ", err);
        toast.error("An error occurred while updating the data");
      });
  };

  const handleExport = async () => {
    let selectedRows;
    selectedRows =
      tableInstance && tableInstance.getSelectedRowModel().flatRows;
    let exportData = [];
    const sideTypeMap = {
      weBuy: 'We buy',
      weSell: 'We sell',
      clientBuys: 'Client buys',
      clientSells: 'Client sells'
    };
    if (selectedRows.length > 0) {
      const selectedAxesDatas = selectedRows.map((row) => row.original);
      selectedAxesDatas.forEach((element) => {
        const formattedDirection = sideTypeMap[element.direction] || element.direction; // Map direction value
        exportData.push({
          feedbackType: element.feedbackType,
          title: element.title,
          client: element.client,
          bond: element.bond,
          sector: element.sector,
          direction: formattedDirection, // Use formattedDirection here
          interest: element.interest,
          clientsTargetLevel: element.clientsTargetLevel,
          clientsAmount: element.clientsAmount,
          comments: element.comments,
          updatedAt: element.updatedAt,
          createdAt: element.createdAt
        });
      });

      /* if (selectedRows.length > 0) {
        const selectedAxesDatas = selectedRows.map((row) => row.original);
        selectedAxesDatas.forEach((element) => {
          exportData.push({
            updatedAt: element.updatedAt,
            createdAt: element.createdAt,
            feedbackType: element.feedbackType,
            title: element.title,
            client: element.client,
            bond: element.bond,
            sector: element.sector,
            direction: element.direction,
            interest: element.interest,
            clientsTargetLevel: element.clientsTargetLevel,
            clientsAmount: element.clientsAmount,
            comments: element.comments,
          });
        });*/
      const headings = [
        [
          "Feedback Type",
          "Title",
          "Client",
          "Bond",
          "Sector",
          "Side",
          "Interest",
          "Clients Target Level",
          "Clients Amount",
          "Comments",
          "Last Update",
          "Created At",
        ],
      ];
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws, headings);
      utils.sheet_add_json(ws, exportData, {
        origin: "A2",
        skipHeader: true,
      });
      utils.book_append_sheet(wb, ws, "Data");
      writeFile(wb, `Feeback Data.xlsx`);
      tableInstance.resetRowSelection();
    }
  };

  const exportPdf = () => {
    const unit = "pt";
    const size = "A1"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);
    const title = "Feedback Data";
    const headers = [
      [
        "Feedback Type",
        "Title",
        "Client",
        "Bond",
        "Sector",
        "Side",
        "Interest",
        "Clients Target Level",
        "Clients Amount",
        "Comments",
        "Last Update",
        "Created At",
      ],
    ];

    let selectedRows =
      tableInstance && tableInstance.getSelectedRowModel().flatRows;
    if (selectedRows.length > 0) {
      const selectedAxesDatas = selectedRows.map((row) => row.original);
      const sideTypeMap = {
        weBuy: 'We buy',
        weSell: 'We sell',
        clientBuys: 'Client buys',
        clientSells: 'Client sells'
      };

      let exportData = selectedAxesDatas.map((element) => {
        const formattedDirection = sideTypeMap[element.direction] || element.direction;
        return [
          element.feedbackType,
          element.title,
          element.client,
          element.bond,
          element.sector,
          formattedDirection, // Use formattedDirection here
          element.interest,
          element.clientsTargetLevel,
          element.clientsAmount,
          element.comments,
          timeZoneChange(element.updatedAt, globalContext.timeZoneValue),
          timeZoneChange(element.createdAt, globalContext.timeZoneValue)
        ];
      });

      let content = {
        head: headers,
        body: exportData,
        startY: 70,
        theme: "grid",
        tableLineColor: [189, 195, 199],
        tableLineWidth: 0.75,
      };
      doc.text(title, marginLeft, 40);
      doc.autoTable(content);
      doc.save(`Feedback Data.pdf`);
      tableInstance.resetRowSelection();
    }
  };

  const generateHTMLTable = (selectedData) => {
    const header = `Feedback Type   | Title | Client | Bond | Sector | Side | Interest | Client Target Level | Client Amount | Comments\n${'-'.repeat(50)}`;
    const rows = selectedData.map(row => `${row.feedbackType.padEnd(15)} | ${row.title.padEnd(15)} | ${row.client ? row.client.padEnd(15) : "No Data"} | ${row.bond ? row.bond.padEnd(15) : "No Data"} | ${row.sector ? row.sector.padEnd(15) : "No Data"} | ${row.direction ? row.direction.padEnd(15) : "No Data"} | ${row.interest ? row.interest.padEnd(15) : "No Data"} | ${row.clientsTargetLevel ? row.clientsTargetLevel.padEnd(15) : "No Data"} | ${row.clientsAmount ? row.clientsAmount.padEnd(15) : "No Data"} | ${row.comments ? row.comments.padEnd(15) : "No Data"}
    `).join('\n');
    return `${header}\n${rows}`;
  };

  /*  const generateFormattedEmailBody = (type, data) => {
     return data.map(row => {
       // Split comments by newline
       const commentsList = row.comments ? row.comments.split('\n').filter(comment => comment.trim() !== '') : [];
       // Format comments with bullet points
       const formattedComments = commentsList.length > 0 ? commentsList.map(comment => `       ◦ ${comment.trim()}`).join('\n') : '';
   
       // Determine the header text based on the presence of `type`
       const headerText = type ? 'My Colleagues’ Feedback' : 'N/A';
   
       // Conditionally include fields only if they have values
       return `
   ${row.feedbackType || 'N/A'}:
 
   ${headerText}: ${row.feedbackType || 'N/A'}
       
   ${row.title || ''}
   
   ${row.client || ''}
   
   ${row.teamName ? `    • ${row.teamName}:` : ''}
   
       ${row.bond ? `    • Bond/Sector: ${row.bond}` : ''}
       ${row.interest ? `    • Interested to buy: ${row.interest}` : ''}
       ${row.clientsTargetLevel ? `    • Client’s target level: ${row.clientsTargetLevel}` : ''}
       ${row.clientsAmount ? `    • Client’s amount: ${row.clientsAmount}` : ''}
       ${row.maturity ? `    • Maturity: ${row.maturity}` : ''}
       ${formattedComments ? `    • Comment:\n${formattedComments}` : ''}
       `;
     }).join('\n\n');
   }; */

  /* const generateFormattedEmailBody = (type, data) => {
    let lastTitle = ''; // To track the last used title
    let lastTeam = ''; // To track the last used team name
    let hasPrintedHeader = false; // To ensure the header is printed only once

    return data.map(row => {
      // Split comments by newline
      const commentsList = row.comments ? row.comments.split('\n').filter(comment => comment.trim() !== '') : [];
      // Format comments with bullet points
      const formattedComments = commentsList.length > 0 ? commentsList.map(comment => `           ◦ ${comment.trim()}`).join('\n') : '';

      // Initialize the email body
      let emailBody = '';

      // Print the header text only once
      if (!hasPrintedHeader && type) {
        emailBody += `My Colleagues’ Feedback: ${capitalizeFirstLetter(type)}\n\n`;
        hasPrintedHeader = true;
      }

      // Conditionally add the title if it's different from the last one
      if (row.title && row.title !== lastTitle) {
        emailBody += `${row.title}\n\n`;
        lastTitle = row.title; // Update the last title
      }

      // Conditionally add team and salesperson details
      if (row.teamName && row.teamName !== lastTeam) {
        emailBody += `${row.teamName}\n\n`;
        lastTeam = row.teamName; // Update the last team name
      }

      // Handle fields based on the type
      if (type === 'axes' || type === 'primary') {
        // Show all fields for "axes" type
        if (row.client) emailBody += `    ${row.client}\n`;
        if (row.bond) emailBody += `        • Bond/Sector: ${row.bond}\n`;
        if (row.interest) emailBody += `        • Interested to buy: ${row.interest}\n`;
        if (row.clientsTargetLevel) emailBody += `        • Client’s target level: ${row.clientsTargetLevel}\n`;
        if (row.clientsAmount) emailBody += `        • Client’s amount: ${row.clientsAmount}\n`;
        if (row.maturity) emailBody += `        • Maturity: ${row.maturity}\n`;
        if (formattedComments) emailBody += `        • Comment:\n${formattedComments}\n`;

      } else if (type === 'general') {
        // Show only title, client, and comments for "general" type
        if (row.client) emailBody += `\n\n${row.client}`;
        if (formattedComments) emailBody += `\n    • Comment:\n${formattedComments}`;
      }

      // Trim any unnecessary whitespace at the start and end of the string
      return emailBody.trim();
    }).filter(body => body).join('\n\n'); // Filter out any empty email bodies
  }; */

  const generateFormattedEmailBody = (type, data) => {
    let hasPrintedDate = false; // To ensure the date is printed only once
    let groupedData = {}; // Group entries by feedback type

    // Group data by feedbackType
    data.forEach(row => {
      if (!groupedData[row.feedbackType]) {
        groupedData[row.feedbackType] = [];
      }
      groupedData[row.feedbackType].push(row);
    });

    let emailBody = '';

    // Print the date only once
    if (!hasPrintedDate) {
      const currentDate = new Date().toLocaleDateString('en-GB'); // Day/Month/Year format
      emailBody += `${currentDate}:\n\n`;
      hasPrintedDate = true;
    }

    // Iterate over each feedbackType group
    for (const [feedbackType, rows] of Object.entries(groupedData)) {
      // Add feedback type header
      emailBody += `Title: ${capitalizeFirstLetter(feedbackType)} Feedback Summary\n`;

      let salesCounter = 1; // Reset the counter for each feedback type group

      // Iterate over each row within the feedback type group
      rows.forEach(row => {
        // Split comments by newline and remove empty lines
        const commentsList = row.comments ? row.comments.split('\n').filter(comment => comment.trim() !== '') : [];
        // Format comments with bullet points
        const formattedComments = commentsList.length > 0 ? commentsList.map(comment => `              ◦ ${comment.trim()}`).join('\n') : '';

        // Add the title for each feedback entry
        if (row.title) {
          emailBody += `\n    • Sales-T${salesCounter}:\nTitle (${row.title}):`;
          salesCounter++; // Increment the counter for the next entry
        }

        // Handle fields based on feedback type
        if (row.feedbackType === 'Axes' || row.feedbackType === 'Primary') {
          if (row.client) emailBody += `\n        ◦ Client (${row.client})`;
          if (row.bond) emailBody += `\n            ◦ Bond: ${row.bond}`;
          if (row.direction) emailBody += `\n            ◦ Side: ${capitalizeFirstLetter(row.direction.replace('weSell', 'We sell').replace('weBuy', 'We buy'))}`;
          if (row.interest) emailBody += `\n            ◦ Interest: ${row.interest}`;
          if (row.clientsTargetLevel) emailBody += `\n            ◦ Client’s Target Level: ${row.clientsTargetLevel}`;
          if (row.clientsAmount) emailBody += `\n            ◦ Client’s Amount: ${row.clientsAmount}`;
          if (formattedComments) emailBody += `\n            • Comment:\n${formattedComments}`;
        } else if (row.feedbackType === 'General') {
          if (row.client) emailBody += `\n            ◦ Client (${row.client})`;
          if (formattedComments) emailBody += `\n            • Comment:\n${formattedComments}`;
        }
        emailBody += '\n';
      });

      emailBody += '\n\n'; // Add spacing between feedback type groups
    }

    return emailBody.trim(); // Return the formatted email body
  };

  const sendEmail = async () => {
    let selectedRows =
      tableInstance && tableInstance.getSelectedRowModel().flatRows;
  
    if (selectedRows.length > 0) {
      const selectedData = selectedRows.map((row) => row.original);
      const formattedEmailBody = generateFormattedEmailBody(type, selectedData);
  
      // Copy formatted email body to clipboard
      try {
        await navigator.clipboard.writeText(formattedEmailBody);
        alert("Email content copied to clipboard! You can now paste it in Gmail.");
  
        // Open Gmail compose link (without body content)
        const subject = "Selected Table Data";
        const gmailLink = `https://mail.google.com/mail/?view=cm&fs=1&su=${encodeURIComponent(subject)}`;
        window.open(gmailLink, '_blank');
      } catch (error) {
        console.error('Failed to copy email content:', error);
        alert("Failed to copy email content. Please try again.");
      }
  
      // Reset row selection
      tableInstance.resetRowSelection();
    }
  };


 /*  const sendEmail = () => {
    let selectedRows =
      tableInstance && tableInstance.getSelectedRowModel().flatRows;

    if (selectedRows.length > 0) {
      const selectedData = selectedRows.map((row) => row.original);
      const formattedEmailBody = generateFormattedEmailBody(type, selectedData);

      console.log("formattedEmailBody", formattedEmailBody);

      // Prepare the Gmail compose link
      const subject = "Selected Table Data";
      const body = `\n\n${formattedEmailBody}\n\n`;
      const gmailLink = `https://mail.google.com/mail/?view=cm&fs=1&su=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(body)}`;
      window.open(gmailLink, '_blank');
      tableInstance.resetRowSelection();
    }
  }; */

  /*  const sendEmail = () => {
     let selectedRows = tableInstance && tableInstance.getSelectedRowModel().flatRows;
     if (selectedRows.length > 0) {
       const selectedData = selectedRows.map(row => row.original);
       const plainTextTable = generateHTMLTable(selectedData);
 
       // Prepare the mailto link
       const subject = 'Selected Table Data';
       const body = `\n\nTable:\n\n${plainTextTable}\n\n`;
       const mailtoLink = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
       window.location.href = mailtoLink;
       tableInstance.resetRowSelection();
     }
   } */


  // Function to check if a value is valid (not undefined, null, or empty)
  const isValidValue = (value) => value !== undefined && value !== null && value !== '';

  const returnMiddlecopyValue = (columnVisibility, row) => {
    const sideTypeMap = {
      weBuy: 'We buy',
      weSell: 'We sell',
      clientBuys: 'Client buys',
      clientSells: 'Client sells'
    };

    const formattedDirection = sideTypeMap[row.direction] || row.direction;
    let value = ''
    if (row.feedbackType === 'Primary') {
      if (isValidValue(row.client) && columnVisibility.client) value += `${isValidValue(row.title) ? ` - ${row.client}` : `${row.client}`}`;
      if (isValidValue(row.bond) && columnVisibility.bond) value += ` - ${row.bond}`;
      if (isValidValue(row.sector) && columnVisibility.sector) value += `/${row.sector}`;
      if (isValidValue(row.clientsTargetLevel) && columnVisibility.clientsTargetLevel) value += ` - ${row.clientsTargetLevel}`;
      if (isValidValue(row.clientsAmount) && columnVisibility.clientsAmount) value += ` - ${row.clientsAmount}`;
    } else if (row.feedbackType === 'Axes') {
      if (isValidValue(row.client) && columnVisibility.client) value += `${isValidValue(row.title) ? ` - ${row.client}` : `${row.client}`}`;
      // below is new value inserted in case of axes
      if (isValidValue(row.direction) && columnVisibility.direction) value += ` - ${formattedDirection}`;

      if (isValidValue(row.bond) && columnVisibility.bond) value += ` - ${row.bond}`;
      if (isValidValue(row.sector) && columnVisibility.sector) value += `/${row.sector}`;
      if (isValidValue(row.clientsTargetLevel) && columnVisibility.clientsTargetLevel) value += ` - ${row.clientsTargetLevel}`;
      if (isValidValue(row.clientsAmount) && columnVisibility.clientsAmount) value += ` - ${row.clientsAmount}`;
    } else if (row.feedbackType === 'General') {
      if (isValidValue(row.client) && columnVisibility.client) value += `${isValidValue(row.title) ? ` - ${row.client}` : `${row.client}`}`;
    }
    // else {
    //   if (row.feedbackType === 'Primary') {
    //     if (isValidValue(row.client) && columnVisibility.client) value += `${isValidValue(row.title) ? ` - ${row.client}` : `${row.client}`}`;
    //     if (isValidValue(row.bond) && columnVisibility.bond) value += ` - ${row.bond}`;
    //     if (isValidValue(row.sector) && columnVisibility.sector) value += `/${row.sector}`;
    //     if (isValidValue(row.clientsTargetLevel) && columnVisibility.clientsTargetLevel) value += ` - ${row.clientsTargetLevel}`;
    //     if (isValidValue(row.clientsAmount) && columnVisibility.clientsAmount) value += ` - ${row.clientsAmount}`;
    //   } else if (row.feedbackType === 'Axes') {
    //     if (isValidValue(row.client) && columnVisibility.client) value += `${isValidValue(row.title) ? ` - ${row.client}` : `${row.client}`}`;
    //     // below is new value inserted in case of axes
    //     if (isValidValue(row.direction) && columnVisibility.direction) value += ` - ${row.direction}`;

    //     if (isValidValue(row.bond) && columnVisibility.bond) value += ` - ${row.bond}`;
    //     if (isValidValue(row.sector) && columnVisibility.sector) value += `/${row.sector}`;
    //     if (isValidValue(row.clientsTargetLevel) && columnVisibility.clientsTargetLevel) value += ` - ${row.clientsTargetLevel}`;
    //     if (isValidValue(row.clientsAmount) && columnVisibility.clientsAmount) value += ` - ${row.clientsAmount}`;
    //   } else if (row.feedbackType === 'General') {
    //     if (isValidValue(row.client) && columnVisibility.client) value += `${isValidValue(row.title) ? ` - ${row.client}` : `${row.client}`}`;
    //   }
    // }
    return value;
  }

  const copyRowValue = (row) => {
    const {
      title, client, bond, sector, clientsTargetLevel, clientsAmount, comments, direction, currency, size, coupon, ticker, maturity, govSpread, zSpread, createdByUserName, teamName,
      //yield: yieldValue, // Use yield as yield is a reserved keyword
      cashPrice, isin, } = row.original;

    let key = sidebarModule[3];
    let data = JSON.parse(localStorage.getItem('columnVisibility'))
    let columnVisibility;
    if (type === 'primary') {
      columnVisibility = data[key]['primary']
    } else if (type === 'axes') {
      columnVisibility = data[key]['axes']
    } else if (type === 'general') {
      columnVisibility = data[key]['general']
    } else {
      columnVisibility = data[key]['all']
    }

    // Construct the formatted string

    let formattedValue = '';

    if (isValidValue(teamName)) formattedValue += `${capitalizeFirstLetter(teamName)}`;
    if (isValidValue(createdByUserName)) formattedValue += ` - ${capitalizeFirstLetter(createdByUserName)}`;

    if (isValidValue(title) && columnVisibility.title) formattedValue += ` - ${title}`;
    formattedValue += returnMiddlecopyValue(columnVisibility, row.original)
    console.log('formattedValue before:', formattedValue);
    if (isValidValue(comments) && columnVisibility.comments) formattedValue += ` - ${comments}`;

    console.log('formattedValue after:', formattedValue);

    // Trim any trailing dashes and spaces
    formattedValue = formattedValue.trim();

    // Copy to clipboard logic
    if (navigator?.clipboard && navigator.clipboard.writeText) {
      //console.log("first method");
      navigator.clipboard
        .writeText(formattedValue)
        .then(() => {
          //console.log("Copied to clipboard 1st methos", formattedValue);
          toast.success(text.recordCopied);
        })
        .catch((err) => {
          console.error("Failed to copy:", err);
        });
    } else {
      //console.log("second method");
      // Fallback for older browsers
      const textArea = document.createElement("textarea");
      textArea.value = formattedValue;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand("copy");
        //console.log("Copied to clipboard 2nd method:", formattedValue);
        toast.success(text.recordCopied);
      } catch (err) {
        console.error("Failed to copy:", err);
      }
      document.body.removeChild(textArea);
    }
  };

  return (
    <>
      {showDeleteModal && <DeleteModal handleDelete={handleDelete} />}

      <div className="mb-10">

        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-4">
            <h4 className="text-black h3 mb-3 cus-size"> {type == "manage-colleague-feedback" ? "Colleagues'" : type} {text.Feedback}</h4>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-8">
            <div className="mb-3 mb-sm-3">

              {/* {dailyTopAxesData?.length > 0 && ( */}
              <div className="row justify-content-between">

                <div className="col-2">

                  <div className="d-flex justify-content-md-center justify-content-lg-end d-md-none d-sm-block">
                    <i
                      className="icon-copy fa fa-eye-slash pr-20 cursor bg-custom-eye rounded-circle p-2 mr-4"
                      aria-hidden="true"
                      onClick={handlePopoverClick}
                    ></i>
                    <Overlay
                      show={showPopover}
                      target={target}
                      placement="bottom"
                      containerPadding={20}
                      className="setOverlay"
                      ref={popoverRef}
                    >
                      <Popover id="popover-basic">
                        <Popover.Body >
                          <div className="inline-block  rounded">
                            <div className="d-flex align-items-center p-1 left-margin">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="10 0 512 512" width="20" height="20"><path d="M152.1 38.2c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 113C-2.3 103.6-2.3 88.4 7 79s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zm0 160c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 273c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zM224 96c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zM160 416c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H192c-17.7 0-32-14.3-32-32zM48 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" /></svg>
                              <span className="ml-2"><b>{text.filterColumn}</b> </span>
                            </div>
                            <div className="row p-2">
                              {tableInstance && tableInstance.getAllLeafColumns().filter(column => column.id !== 'action' && column.id !== 'select').map(column => {
                                return (
                                  <div key={column.id} className="col-6 px-1">
                                    <label className="cursor">
                                      <input
                                        {...{
                                          type: 'checkbox',
                                          checked: checkboxState[column.id] !== undefined ? checkboxState[column.id] : column.getIsVisible(),
                                          onChange: combinedOnChangeHandler(column),
                                        }}
                                      />{' '}
                                      {column.columnDef.header}
                                    </label>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </Popover.Body>
                      </Popover>
                    </Overlay>
                  </div>

                </div>
                <div className="col-10">
                  <div className="d-flex flex-wrap flex-md-nowrap justify-content-end">
                    <span className="d-none d-md-block">
                      <i
                        className="icon-copy fa fa-eye-slash pr-20 cursor bg-custom-eye rounded-circle p-2 mr-4"
                        aria-hidden="true"
                        onClick={handlePopoverClick}
                      ></i>
                      <Overlay
                        show={showPopover}
                        target={target}
                        placement="bottom"
                        containerPadding={20}
                        className="setOverlay"
                        ref={popoverRef}
                      >
                        <Popover id="popover-basic">
                          <Popover.Body>
                            <div className="inline-block  rounded">
                              <div className="d-flex align-items-center p-1 left-margin">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="10 0 512 512" width="20" height="20"><path d="M152.1 38.2c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 113C-2.3 103.6-2.3 88.4 7 79s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zm0 160c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 273c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zM224 96c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zM160 416c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H192c-17.7 0-32-14.3-32-32zM48 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" /></svg>
                                <span className="ml-2"><b>{text.filterColumn}</b> </span>
                              </div>
                              <div className="row p-2">
                                {tableInstance && tableInstance.getAllLeafColumns().filter(column => column.id !== 'action' && column.id !== 'select').map(column => {
                                  return (
                                    <div key={column.id} className="col-6 px-1">
                                      <label className="cursor">
                                        <input
                                          {...{
                                            type: 'checkbox',
                                            checked: checkboxState[column.id] !== undefined ? checkboxState[column.id] : column.getIsVisible(),
                                            onChange: combinedOnChangeHandler(column),
                                          }}
                                        />{' '}
                                        {column.columnDef.header}
                                      </label>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          </Popover.Body>
                        </Popover>
                      </Overlay>
                    </span>
                    <span>

                      <button
                        type="button"
                        className="btn btn-grey mr-md-4 w-auto mt-2 mr-2 mt-md-0"
                        onClick={() => sendEmail()}
                      >
                        <i className="icon-copy fi-mail" aria-hidden="true" />{" "}
                        {text.sendEmail}
                      </button>
                    </span>
                    <span>
                      <button
                        type="button"
                        className="btn btn-grey mr-md-4 w-auto mt-2 mr-2 mt-md-0"
                        onClick={() => handleExport()}
                      >
                        <i
                          className="icon-copy fa fa-file-excel-o"
                          aria-hidden="true"
                          style={{ color: "green" }}
                        />{" "}
                        {text.exportExcel}
                      </button>
                    </span>
                    <span>
                      <button
                        type="button"
                        className="btn btn-grey mr-md-4 w-auto mt-2 mr-2 mt-md-0"
                        onClick={() => exportPdf()}
                      >
                        <i
                          className="icon-copy fa fa-file-pdf-o"
                          aria-hidden="true"
                          style={{ color: "red" }}
                        />{" "}
                        {text.exportPDF}
                      </button>
                    </span>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>


        <div className="card-box">
          <DataTableEditable
            data={data}
            //columns={bankColumns}
            columns={columns}
            columnVisibility={columnVisibility}
            setColumnVisibility={setColumnVisibility}
            setTableInstance={setTableInstance}
            updateData={updateData}
            isLoading={isLoading}
            addingRow={false}
          />
        </div>
      </div>
    </>
  );
};

export default Index;
