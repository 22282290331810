import React, { useState } from "react";
import GlobalContext from "./globalContext";

const GlobalState = (props) => {
	const [timeZoneValue, setTimeZoneValue] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone,)
	return (
		<GlobalContext.Provider value={{ timeZoneValue, setTimeZoneValue }}>
			{props.children}
		</GlobalContext.Provider>
	)
}

export default GlobalState;