import BaseApi from "./baseApi";

class AuthApi extends BaseApi {
    async Login(data) {
        return await this.API(
            {
                url: "/auth/login",
                data: data,
                method: "post"
            }
        )
    }

    async updateDeviceToken(data) {
        return await this.API(
            {
                url: "/auth/store-token",
                data: data,
                method: "post"
            }
        )
    }

    async forgotPassword(email) {
        console.log(email)
        return await this.API({
            url: "/auth/forgotpassword",
            data: email,
            method: "post"
        });
    }

    async resetPassword(token, password, confirmPassword) {
        console.log(token,password,confirmPassword);
        return await this.API({
            url: `/auth/resetpassword/${token}`,
            data: { password, confirmPassword },
            method: "post"
        });
    }

}

export default AuthApi;