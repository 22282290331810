import React, { useContext, useState } from 'react'
import TimezoneSelect from "react-timezone-select";
import GlobalContext from '../../context/global/globalContext';

const Index = () => {
  const globalContext = useContext(GlobalContext);
  const [selectedTimezone, setSelectedTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone,
  )

  const onChange = (e) => {
    // console.log('state value from component:', e.value);
    globalContext.setTimeZoneValue(e.value)
    setSelectedTimezone(e.value)
  }

  return (
    <TimezoneSelect value={selectedTimezone} onChange={(e) => onChange(e)} />
  )
}

export default Index;
