import BaseApi from "./baseApi";

class CommonApi extends BaseApi {

	async changePassword(data) {
		return await this.API({
			url: "/common/changePassword",
			data: data,
			method: "post"
		});
	}

	async getUserInfo(data) {
		return await this.API({
			url: "/common/getUserInfo",
			data: data,
			method: "post"
		});
	}

	async updateUserInfo(data) {
		return await this.API({
			url: "/common/updateUserInfo",
			data: data,
			method: "post"
		});
	}

}

export default CommonApi;