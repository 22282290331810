import React from "react";
import { Route, Routes, Navigate } from 'react-router-dom';
import Dashboard from "../pages/master/dashboard";
import Profile from "../pages/master/profile";
import AddBank from "../pages/master/ManageBanks/AddBank";
import AddManager from "../pages/master/ManageTeamMember/AddTeamMember";
import AddTeam from "../pages/master/ManageTeamMember/AddTeam";
import ManageBanks from "../pages/master/ManageBanks";
/* import AddFeedback from "../pages/master/ManageFeedbacks/AddFeedback";
import ManageFeedbacks from "../pages/master/ManageFeedbacks";
import AddAxe from "../pages/master/ManageAxe/AddAxe";
import ManageAxes from "../pages/master/ManageAxe"; */

import ManageTeamMember from "../pages/master/ManageTeamMember";
import Header from "../layouts/header";
import AutoLogout from "../pages/auth/logout";
import ChangePassword from "../pages/master/ChangePassword"


/* const PrimaryFeedback = () => <div>Primary Feedback</div>;
const AxesFeedback = () => <div>Axes Feedback</div>;
const GeneralFeedback = () => <div>General Feedback</div>; */

const MasterAdminRouter = () => {
  return (
    <>
      <div className="main">
        <div className="main-container" style={{ height: 'inherit' }}>
          <Header />
          <div className="pd-ltr-20 xs-pd-20-10" style={{ height: '100%', overflow: 'auto' }}>
            <Routes>
              <Route path="/dashboard" element={<Dashboard />} />
              {/* magage axe */}
             {/*  <Route path="/addAxe/:id" element={<AddAxe />} />
              <Route path="/addAxe" element={<AddAxe />} />
              <Route path="/manageAxe" element={<ManageAxes />} /> */}

              {/* magage feedback */}
              {/* <Route path="/manageFeedback" element={<ManageFeedbacks />}>
                <Route index element={<Navigate to="/manageFeedback/primary" />} />
                <Route path="primary" element={<PrimaryFeedback />} />
                <Route path="axes" element={<AxesFeedback />} />
                <Route path="general" element={<GeneralFeedback />} />
            </Route> */}
             {/*  <Route path="/addFeedback/:id" element={<AddFeedback />} />
              <Route path="/addFeedback" element={<AddFeedback />} />
              <Route path="/manageFeedback" element={<ManageFeedbacks />} /> */}
              
              {/* magage banks */}
              <Route path="/addBank/:id" element={<AddBank />} />
              <Route path="/addBank" element={<AddBank />} />
              <Route path="/manageBanks" element={<ManageBanks />} />

              {/* mangae team members */}
              <Route path="/addManager/:id" element={<AddManager />} />
              <Route path="/addManager" element={<AddManager />} /> 
              <Route path="/manageTeamMember/:bankId" element={<ManageTeamMember />} />
              <Route path="/manageTeamMember" element={<ManageTeamMember />} />
              <Route path="/assignTeamMember" element={<AddTeam />} /> 

              {/* change password */}
              <Route path="/changePassword" element={<ChangePassword />} />

              {/* logout */}
              <Route path="/logout" element={<AutoLogout />} />
              <Route path="/*" element={<Navigate to={`/manageBanks`} />} />
            </Routes>
          </div>
        </div>

      </div>
    </>
  )
}
export default MasterAdminRouter;
