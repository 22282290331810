import React, { useEffect, useState } from 'react'
import text from '../../../util/text';
import NotificationApi from "../../../api/notificationApi";
import { DeleteModal } from "../../../components/Modal/DeleteModal";
import { Cookies } from "react-cookie";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const Index = () => {
  const notificationApi = new NotificationApi();
  const [notifications, setNotifications] = useState([]);
  const [postponeDays, setPostponeDays] = useState({});
  const [isPostponing, setIsPostponing] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const cookies = new Cookies();
  let cookieData = cookies.get('userInfo');
  let UserId = cookieData._id;

  useEffect(() => {
    getNotifications();
  }, [])

  const getNotifications = async () => {
    notificationApi.getUserNotification(UserId).then((res) => {
      if (res.success) {
        setNotifications(res.data);
        //setIsLoading(false)
      }
    }).catch((err) => {
      console.error("Getting notification error => ", err);
      //setIsLoading(false)
    });
  }

  const handleDecision = async (notificationId, decision) => {
    try {
      const res = await notificationApi.sendNotificationDecision({
        notificationId,
        decision,
        userId: UserId,
      });
      if (res.success) {
        //alert('Decision submitted successfully!');
        toast.success(res.message);
        getNotifications(); // Optionally refresh the list
      }
    } catch (error) {
      //toast.error(res.message);
      console.error('Error submitting decision:', error);
    }
  };

  const handlePostpone = (notificationId) => {
    setIsPostponing(prevState => ({
      ...prevState,
      [notificationId]: !prevState[notificationId] // Toggle the current state
    }));
  };

  const handlePostponeSubmit = async (notificationId) => {
    try {
      const res = await notificationApi.sendNotificationDecision({
        notificationId,
        decision: 'postpone',
        userId: UserId,
        days: postponeDays[notificationId] || 0,
      });
      if (res.success) {
        toast.success(res.message);
        getNotifications(); // Optionally refresh the list
      }
    } catch (error) {
      //toast.error(res.message);
      console.error('Error submitting postpone decision:', error);
    }
  };

  const handlePostponeChange = (notificationId, value) => {
    setPostponeDays(prevState => ({
      ...prevState,
      [notificationId]: value
    }));
  };

  const handleDelete = () => {
    notificationApi
      .cancelNotificationRequest({ id: deleteId._id })
      .then((res) => {
        if (res.success) {
          toast.success(res.message);
          setShowDeleteModal(false);
          setDeleteId(null);
          getNotifications();
        }
      })
      .catch((err) => {
        setShowDeleteModal(false);
        setDeleteId(null);
        console.error("Deleting bank error => ", err);
      });
  };

  return (
    <>
    {showDeleteModal && <DeleteModal handleDelete={handleDelete} />}
      <div className='axe-notification'>
        <div className="min-height-200px mb-20 profile-section">
          <div className="p-2">
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div className="title">
                  <p style={{ fontSize: "20px", fontWeight: 500 }}>
                    {text.notification}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="px-3 py-5 card-box height-100-p">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-30 bg-white">
                <div className="profile-tab height-100-p">
                  <div className="tab height-100-p">
                    <div className="height-100-p">
                      <div className="card">
                        <div className='card-body'>
                          {notifications?.length === 0 ? (
                            <ul>
                              <li>
                                <p className="text-center">No data found</p>
                              </li>
                            </ul>
                          ) : (
                            <ul>
                              {notifications?.map((notification, index) => (
                                <li key={index} className='border-bottom'>
                                  {/* <img src="vendors/images/img.jpg" alt="" />
                                <h3>{notification.createdBy}</h3> */}
                                  <div class="row pb-2 my-2">
                                    <div class="col col-8  d-flex flex-column">
                                      <p className='mb-0'>{notification.message}</p>
                                      {notification.interested === 'yes' && notification.interestedWith === 'yes' && notification.interestedWith && (
                                        <>
                                          <p>Name: {notification.matchedWith?.name}</p>
                                          <p>Phone: {notification.matchedWith?.phone}</p>
                                          <p>Trader: {notification.axeId?.trader}</p>
                                        </>
                                      )}

                                      {notification.interested === 'yes' && notification.interestedWith === 'no' && (
                                        <>
                                          <p className="font-italic text-muted ">Please wait for a response..</p>
                                        </>
                                      )}

                                      {notification.interested === 'no' && notification.interestedWith === 'no' && (
                                        <>
                                          <p className="font-italic text-muted "> Do you want to contact them?</p>
                                        </>
                                      )}
                                       
                                    </div>
                                    <div class="col col-4 d-flex justify-content-end align-items-center">
                                      <div className='row'>
                                        <div class="col">
                                          <div className='d-flex justify-content-end'>
                                          {notification.interested === 'no' && (notification.interestedWith === 'no' || notification.interestedWith === 'yes') && notification.interestedWith &&(
                                              <>
                                                <div class="col d-flex justify-content-end">
                                                  <button type="button" className='btn btn-primary btn-sm' onClick={() => handleDecision(notification._id, 'yes')}>Yes</button>
                                                  <button type="button" className='ml-1 btn btn-danger btn-sm' onClick={() => handleDecision(notification._id, 'no')}>No</button>
                                                </div>
                                              </>
                                            )}
                                            {notification.interested === 'yes' && notification.interestedWith === 'no' && (
                                              <>
                                                <span className="font-italic text-muted mr-3">
                                                  <div className="circle cursor" data-toggle="modal" data-target="#alert-modal"
                                                    onClick={() => {
                                                      setShowDeleteModal(true);
                                                      setDeleteId(notification);
                                                    }}>
                                                    <i className="icon-copy fa fa-trash plus-icon"></i>
                                                  </div>
                                                </span>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Index;