import React, { useContext, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DataTable from "../../../components/DataTable/DataTable";
import { useEffect } from "react";
import TeamApi from "../../../api/memberApi";
import { toast } from "react-toastify";
import { Popover, Overlay } from "react-bootstrap";
import { DeleteModal } from "../../../components/Modal/DeleteModal";
import text from "../../../util/text";
import roles, { capitalizeFirstLetter, sidebarModule } from "../../../util/constants";
import { Loader } from "../../../components/Loader";
import { Cookies } from "react-cookie";
import GlobalContext from "../../../context/global/globalContext";
import { timeZoneChange } from '../../../util/constants'

const Index = () => {
  const globalContext = useContext(GlobalContext);
  const { bankId } = useParams();
  const api = new TeamApi();
  const navigate = useNavigate();
  const [teamManager, setTeamManager] = useState([]);
  const [showPopover, setShowPopover] = useState(false);
  const [target, setTarget] = useState(null);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [tableInstance, setTableInstance] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState(null);
  const cookies = new Cookies();
  let cookieData = cookies.get('userInfo');
  const [isLoading, setIsLoading] = useState(false)
  const [checkboxState, setCheckboxState] = useState({});
  // for bank dropdown
  const [dropdownBanks, setDropdownBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState('');

  // for roles dropdown
  const [selectedRole, setSelectedRole] = useState('');

  // for manager dropdown
  const [uniqueManager, setUniqueManager] = useState([]);
  const [selectedManager, setSelectedManager] = useState('');

  const popoverRef = useRef(null);

  const handleClickOutside = (event) => {
    if (popoverRef.current && !popoverRef.current.contains(event.target)) {
      setShowPopover(false);
    }
  };

  useEffect(() => {
    if (showPopover) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPopover]);

  useEffect(() => {
    if (bankId && bankId !== '') {
      setSelectedBank(bankId);
      getTeamMembers({ bankId: bankId });
      setIsLoading(true)
    } else {
      setSelectedBank('');
      getTeamMembers();
      setIsLoading(true)
    }
  }, [bankId])

  useEffect(() => {
    // for unique banks
    const uniqueBanks = teamManager.reduce((unique, item) => {
      if (item.bankName && item.bankName.trim() !== '') {
        const exists = unique.some((entry) => entry._id === item.AssociatedWith && entry.bankName === item.bankName);
        if (!exists) {
          unique.push({
            _id: item.AssociatedWith,
            bankName: item.bankName,
          });
        }
      }
      return unique;
    }, []).sort((a, b) => a.bankName.localeCompare(b.bankName));
    setDropdownBanks(uniqueBanks);

    // Update uniqueManager based on selectedBank and selectedRole
    const filteredManagers = teamManager.filter(item => {
      return (!selectedBank || item.AssociatedWith === selectedBank) && (!selectedRole || item.userRole === selectedRole);
    });
    const selectedBankManagers = filteredManagers.filter(item => item.isManager);
    setUniqueManager(selectedBankManagers);

    // Reset selectedManager when bank or role changes
    setSelectedManager('');

  }, [teamManager, selectedBank, selectedRole]);

  const getTeamMembers = async (bankId = null) => {
    api.getTeamMembers(bankId).then((res) => {
      if (res.success) {
        if ((cookieData?.userRole == 'trader' || cookieData?.userRole == 'sales' || cookieData?.userRole == 'syndicate') && cookieData?.isManager) {
          let filterData = res.data.filter(item => {
            if (item.AssociatedWith == cookieData.AssociatedWith && !item.isManager && item.managerId == cookieData._id && item.accountStatus == 'active' && item.userRole == cookieData.userRole) {
              return item;
            }
          })
          setTeamManager(filterData);
          setIsLoading(false)
        } else {
          setTeamManager(res.data);
          setIsLoading(false)
        }
      }
    }).catch((err) => {
      console.error("Getting team managers error => ", err);
      setIsLoading(false)
    });
  }

  const data = useMemo(() => {
    let filteredData = teamManager;
    // for bank dropdown
    if (selectedBank) {
      filteredData = filteredData.filter((member) => member.AssociatedWith === selectedBank);
    }

    // for role dropdown
    if (selectedRole) {
      filteredData = filteredData.filter((member) => member.userRole === selectedRole);
    }

    // for manager dropdown
    if (selectedManager) {
      filteredData = filteredData.filter((member) => member.managerId === selectedManager);
    }

    return filteredData;
  }, [teamManager, selectedBank, selectedRole, selectedManager]);

  const bankColumns = [
    {
      header: 'Bank Name',
      accessorKey: 'bankName',
    },
    {
      header: 'Team Member',
      accessorKey: 'name',
    },
    {
      header: 'Role',
      accessorKey: 'userRole',
      cell: ({ row }) => (
        <div className="d-flex justify-content-between">
          <span>{capitalizeFirstLetter(row.original.userRole)}</span>
        </div>
      )
    },
    {
      header: 'Manager',
      accessorKey: 'isManager',
      cell: ({ row }) => (
        <div className="d-flex justify-content-between">
          <span>{`${row.original.isManager ? 'Yes' : 'No'} `}</span>
        </div>
      )
    },
    {
      header: 'Under Manager',
      accessorKey: 'managerName',
      cell: ({ row }) => (
        <div className="d-flex justify-content-between">
          <span>{`${row.original.managerName ? row.original.managerName : ''} `}</span>
        </div>
      )
    },
    {
      header: 'E-mail',
      accessorKey: 'email',
    },
    {
      header: 'Phone',
      accessorKey: 'phone',
    },
    {
      header: 'Created Date',
      accessorKey: 'createdAt',
      cell: ({ row }) => (
        <div className="d-flex justify-content-left">
          {timeZoneChange(row.original.createdAt, globalContext.timeZoneValue)}
        </div>
      ),
    },
    {
      header: 'Status',
      accessorKey: 'status',
      cell: ({ row }) => (
        <div className="d-flex justify-content-center align-items-center">
          <label className="switch ">
            <input type="checkbox" className="primary" id={`customSwitch${row.id}`}
              checked={row.original.accountStatus === 'active'}
              onChange={() => {
                handleActivateDeactivate(row.original._id)
              }}
              value={row.original.accountStatus}
              disabled={row.original.accountStatus === 'deleted'} />
            <span className="slider round" htmlFor={`customSwitch${row.id}`} data-toggle="tooltip"
              data-placement="top"
              title={row.original.accountStatus === 'deleted' ? 'Account is deleted' : ''}></span>
          </label>
        </div>
      )
    },
    {
      header: 'Action',
      id: 'action',
      cell: ({ row }) => (

        <span className="d-flex justify-content-around align-items-center">
          {
            row.original.accountStatus == 'active' && <>
              <div className="circle cursor"
                onClick={() => handleEdit(row.original)}>
                <i className="icon-copy fa fa-pencil plus-icon"></i>
              </div>

              <div className="circle cursor" data-toggle="modal" data-target="#alert-modal"
                onClick={() => {
                  setShowDeleteModal(true)
                  setDeleteId(row.original)
                }}>
                <i
                  className="icon-copy fa fa-trash plus-icon"
                ></i>
              </div>

            </>
          }
        </span>
      )
    },
  ];

  const filteredBankColumns = (cookieData?.userRole === 'trader' && cookieData?.isManager)
    ? bankColumns.filter(column => (column.accessorKey !== 'isManager' && column.accessorKey !== 'managerName' && column.accessorKey !== 'bankName'))
    : bankColumns;


  const handleEdit = (row) => {
    navigate(`/addManager/${row._id}`);
  };

  const handleActivateDeactivate = (rowId) => {
    api.activatDeactivateTeamManager({ id: rowId }).then((res) => {
      if (res.success) {
        toast.success(res.message);
        getTeamMembers();
      }
    }).catch((err) => {
      console.error("Activate/Deactivate team manager error => ", err);
    });
  };

  const handleDelete = () => {
    let sendObj = {
      id: deleteId._id,
      addedBy: 'trader',
      underMangerId: cookieData._id
    }
    api.deleteTeamManager(sendObj).then((res) => {
      if (res.success) {
        toast.success(res.message);
        getTeamMembers();
        setShowDeleteModal(false)
        setDeleteId(null)
      }
    }).catch((err) => {
      setShowDeleteModal(false);
      setDeleteId(null);
      console.error("Deleting Team Manager error => ", err);
    });
  };

  const handlePopoverClick = (event) => {
    setTarget(event.target);
    setShowPopover(!showPopover);
  };


  // to persists checkbox changes in filters when users log in/log out
  useEffect(() => {
    const storedVisibility = JSON.parse(localStorage.getItem('columnVisibility'));
    let data;
    if (storedVisibility && storedVisibility.teamMember) {
      data = storedVisibility.teamMember
      setColumnVisibility(data);
      setCheckboxState(data);
    } else {
      initializeColumnVisibility();
    }
  }, []);

  const initializeColumnVisibility = () => {
    const initialVisibility = bankColumns.reduce((acc, column) => {
      acc[column.id] = true;
      return acc;
    }, {});
    setColumnVisibility(initialVisibility);
    setCheckboxState(initialVisibility);
    try {
      let key = sidebarModule[4]
      let data = {
        ...JSON.parse(localStorage.getItem('columnVisibility')),
        [key]: {}
      }
      data[key] = initialVisibility
      const serializedVisibility = JSON.stringify(data);
      localStorage.setItem('columnVisibility', serializedVisibility);
    } catch (error) {
      console.error('Error serializing column visibility:', error);
    }
  };

  const combinedOnChangeHandler = (column) => (event) => {
    column.getToggleVisibilityHandler()(event);
    setCheckboxState((prevState) => {
      const newState = {
        ...prevState,
        [column.id]: event.target.checked,
      };

      let key = sidebarModule[4]
      let data = JSON.parse(localStorage.getItem('columnVisibility')) || {
        [key]: {},
      };

      data[key][column.id] = event.target.checked;
      localStorage.setItem('columnVisibility', JSON.stringify(data));
      return newState;
    });
  };

  return (
    <>
      {showDeleteModal && <DeleteModal handleDelete={handleDelete} />}
      <div className="mb-30">

        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-4">
            <h4 className="text-black h3 mb-3">{text.sidebarManageTeamManager}</h4>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4">
            {
              cookieData?.userRole !== 'trader' && cookieData?.userRole !== 'sales' && cookieData?.userRole !== 'syndicate' && data?.length > 0 &&
              <>
                <div className="d-flex justify-content-center align-items-center row">

                  {/* for banks */}
                  <div className="col-sm-12 col-md-4 mb-3 mb-md-0">
                    <select
                      value={selectedBank}
                      onChange={(e) => setSelectedBank(e.target.value)}
                      className="custom-select mr-2"
                    >
                      <option value="">Bank</option>
                      {dropdownBanks.map((bank, index) => (
                        <option key={index} value={bank._id}>{bank.bankName}</option>
                      ))}
                    </select>
                  </div>

                  {/* for roles */}
                  <div className="col-sm-12 col-md-4 mb-3 mb-md-0">
                    <select
                      value={selectedRole}
                      onChange={(e) => setSelectedRole(e.target.value)}
                      className="custom-select mr-2"
                    >
                      <option value="">Role</option>
                      {roles.map((role, index) => (
                        <option key={index} value={role}>{capitalizeFirstLetter(role)}</option>
                      ))}
                    </select>
                  </div>

                  {/* for managers */}
                  <div className="col-sm-12 col-md-4 mb-3 mb-md-0">
                    <select
                      value={selectedManager}
                      onChange={(e) => setSelectedManager(e.target.value)}
                      className="custom-select"
                    >
                      <option value="">Manager</option>
                      {uniqueManager.map((manager, index) => (
                        <option key={index} value={manager._id}>{manager.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </>
            }
          </div>
          <div class="col-sm-12 col-md-12 col-lg-4">
            <div className="mb-3 mb-sm-3">
              <div className="d-flex justify-content-between justify-content-md-end justify-content-md-end">
                {/* {dailyTopAxesData?.length > 0 && ( */}
                <>
                  <i
                    className="icon-copy fa fa-eye-slash pr-20 cursor bg-custom-eye rounded-circle p-2 mr-4"
                    aria-hidden="true"
                    onClick={handlePopoverClick}
                  ></i>
                  <Overlay
                    show={showPopover}
                    target={target}
                    placement="bottom"
                    containerPadding={20}
                    className="setOverlay"
                    ref={popoverRef}
                  >
                    <Popover id="popover-basic">
                      <Popover.Body>
                        <div className="inline-block  rounded">
                          <div className="d-flex align-items-center p-1 left-margin">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="10 0 512 512" width="20" height="20"><path d="M152.1 38.2c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 113C-2.3 103.6-2.3 88.4 7 79s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zm0 160c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 273c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zM224 96c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zM160 416c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H192c-17.7 0-32-14.3-32-32zM48 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" /></svg>
                            <span className="ml-2"><b>{text.filterColumn}</b> </span>
                          </div>
                          <div className="row p-2">
                            {tableInstance && tableInstance.getAllLeafColumns().filter(column => column.id !== 'action').map(column => {
                              return (
                                <div key={column.id} className="col-6 px-1">
                                  <label className="cursor">
                                    <input
                                      {...{
                                        type: 'checkbox',
                                        // checked: column.getIsVisible(),
                                        checked: checkboxState[column.id] !== undefined ? checkboxState[column.id] : column.getIsVisible(),
                                        onChange: combinedOnChangeHandler(column),
                                      }}
                                    />{' '}
                                    {column.columnDef.header}
                                  </label>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </Popover.Body>
                    </Popover>
                  </Overlay>
                </>
                {/*  )} */}
                <div className="d-flex justify-content-end align-items-md-center">
                  <input
                    type="button"
                    value={text.addManager}
                    className="btn btn-orange"
                    onClick={() => navigate("/addManager")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card-box">
          <DataTable data={data} columns={filteredBankColumns} columnVisibility={columnVisibility} setColumnVisibility={setColumnVisibility} setTableInstance={setTableInstance} isLoading={isLoading} />
        </div>
      </div>
    </>
  );
};

export default Index;
