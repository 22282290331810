import { useState, useEffect, useRef } from 'react';
import text from '../../util/text';

const TextAreaCell = ({ getValue, table, row, column }) => {
  const initialValue = getValue();
  const { updateData } = table.options.meta;

  const [value, setValue] = useState(initialValue);
  const textAreaRef = useRef(null);

  useEffect(() => {
    if (textAreaRef.current) {
      adjustHeight();
    }
  }, [value]);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleSave = () => {
    if (value !== initialValue) {
      table.options.meta.updateData(row.index, column.id, value, row.original._id);
    }
  };

  /* const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (e.shiftKey) {
        // Shift + Enter pressed
        e.preventDefault();
        setValue((prevValue) => prevValue + `\n${text.commentsBullets} `);
      } else {
        // Enter pressed
        e.preventDefault();
        handleSave();
      }
    }
  };
 */

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (e.shiftKey) {
        // Shift + Enter pressed
        e.preventDefault();
        setValue((prevValue) => prevValue + `\n`);
      } else {
        // Enter pressed
        e.preventDefault();
        handleSave();
      }
    }
  };

  const handleBlur = () => {
    if (value !== undefined && !value.trim()) {
      setValue(text.commentsBullets);
      updateData(row.index, column.id, text.commentsBullets, row.original._id);
    } else {
      handleSave();
    }
  };

  const adjustHeight = () => {
    const textArea = textAreaRef.current;
    textArea.style.height = 'auto';
    textArea.style.height = `${textArea.scrollHeight}px`;
  };

  const handleInput = () => {
    adjustHeight();
  };

  return (
    <div className="d-flex justify-content-center">
      <textarea
        ref={textAreaRef}
        value={value}
        //value={value || '• '}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        onInput={handleInput}
        //placeholder={text.toBeFilled}
        //rows="1"
        style={{ resize: 'both' }}
        className="form-control"
      />
    </div>
  );
};

export default TextAreaCell;