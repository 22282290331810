import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AxeApi from "../../../api/axeApi";
import { toast } from "react-toastify";
import { Cookies } from "react-cookie";
import text from "../../../util/text";

const AddAxe = () => {
  const { id } = useParams();
  const api = new AxeApi();
  const navigate = useNavigate();
  const cookies = new Cookies();
  let cookieData = cookies.get('userInfo');

  const [formData, setFormData] = useState({
    _id: '',
    side: "",
    ticker: "",
    coupon: "",
    maturity: "",
    bond: "",
    size: "",
    currency: "",
    isin: "",
    govSpread: "",
    zSpread: "",
    yield: "",
    trader: "",
    sector: "",
    associatedWith: cookieData.AssociatedWith || "",
    createdBy: cookieData._id,
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (id) {
      api.getAxeById({ id: id }).then((res) => {
        if (res.success) {
          const { _id, side, ticker, coupon, maturity, bond, size, currency, isin, govSpread, zSpread, trader, sector, yield: bondYield } = res.data;
          setFormData({ _id, side, ticker, coupon, maturity, bond, size, currency, isin, govSpread, zSpread, trader, sector, yield: bondYield });
        } else {
          toast.error(res.message);
        }
      }).catch((err) => {
        console.error("Fetch bank by ID error => ", err);
      });
    }
  }, [id]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });

    // Clear errors for the specific field
    const newErrors = { ...errors };
    if (value.trim() !== "") {
      delete newErrors[name];
    }

    setErrors(newErrors);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newErrors = {};

    if (!formData.side) {
      newErrors.side = "side is required";
    }
    if (!formData.ticker) {
      newErrors.ticker = "ticker is required";
    }
    if (!formData.coupon) {
      newErrors.coupon = "coupon is required";
    }
    if (!formData.maturity) {
      newErrors.maturity = "maturity is required";
    }
    if (!formData.bond) {
      newErrors.bond = "bond is required";
    }
    if (!formData.size) {
      newErrors.size = "size is required";
    }
    if (!formData.currency) {
      newErrors.currency = "currency is required";
    }
    if (!formData.isin) {
      newErrors.isin = "isin is required";
    }
    if (!formData.govSpread) {
      newErrors.govSpread = "govSpread is required";
    }
    if (!formData.zSpread) {
      newErrors.zSpread = "zSpread is required";
    }
    if (!formData.yield) {
      newErrors.yield = "yield is required";
    }
    if (!formData.trader) {
      newErrors.trader = "trader is required";
    }
    if (!formData.sector) {
      newErrors.sector = "sector is required";
    }


    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      api.addEditAxe(formData).then((res) => {
        if (res.success) {
          toast.success(res.message);
          setFormData({});
          navigate("/manageAxe");
        } else {
          toast.error(res.message);
          setErrors({});
        }
      }).catch((err) => {
        console.error("Add bank error => ", err);
        setErrors({});
      });
    }
  };


  return (
    <>
      <div className="min-height-200px">
        <div className="pd-20 d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
          <h5 className="text-black mb-3 mb-md-0">{text.addUpdateAxes}</h5>
          <span className="text-black mb-3 mb-md-0 cursor" onClick={() => navigate(-1)}>
            <span className="icon-copy ti-angle-left" />
            {text.backText}
          </span>
        </div>
        <div className="card-box mb-30">
          <div className="pd-20 d-flex">
            <div className="container">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="side">Side</label>
                      <input
                        type="text"
                        className="form-control"
                        id="side"
                        name="side"
                        value={formData.side}
                        onChange={handleChange}
                        placeholder="Enter side"
                      />
                      {errors.side && (
                        <div className="text-danger">
                          {errors.side}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="coupon">
                        Coupon
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="coupon"
                        name="coupon"
                        value={formData.coupon}
                        onChange={handleChange}
                        placeholder="Enter coupon"
                      />
                      {errors.coupon && (
                        <div className="text-danger">
                          {errors.coupon}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="bond">Bond</label>
                      <input
                        type="text"
                        className="form-control"
                        id="bond"
                        name="bond"
                        value={formData.bond}
                        onChange={handleChange}
                        placeholder="Enter bond"
                      />
                      {errors.bond && (
                        <div className="text-danger">
                          {errors.bond}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="currency">
                        Currency
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="currency"
                        name="currency"
                        value={formData.currency}
                        onChange={handleChange}
                        placeholder="Enter currency"
                      />
                      {errors.currency && (
                        <div className="text-danger">
                          {errors.currency}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="govSpread">Gov Spread</label>
                      <input
                        type="text"
                        className="form-control"
                        id="govSpread"
                        name="govSpread"
                        value={formData.govSpread}
                        onChange={handleChange}
                        placeholder="Enter gov spread"
                      />
                      {errors.govSpread && (
                        <div className="text-danger">
                          {errors.govSpread}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="yield">
                        Yield
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="yield"
                        name="yield"
                        value={formData.yield}
                        onChange={handleChange}
                        placeholder="Enter yield"
                      />
                      {errors.yield && (
                        <div className="text-danger">
                          {errors.yield}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="sector">Sector</label>
                      <input
                        type="text"
                        className="form-control"
                        id="sector"
                        name="sector"
                        value={formData.sector}
                        onChange={handleChange}
                        placeholder="Enter sector"
                      />
                      {errors.sector && (
                        <div className="text-danger">
                          {errors.sector}
                        </div>
                      )}
                    </div>

                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="ticker">Ticker</label>
                      <input
                        type="text"
                        className="form-control"
                        id="ticker"
                        name="ticker"
                        value={formData.ticker}
                        onChange={handleChange}
                        placeholder="Enter ticker"
                      />
                      {errors.ticker && (
                        <div className="text-danger">
                          {errors.ticker}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="maturity">Maturity</label>
                      <input
                        type="text"
                        className="form-control"
                        id="maturity"
                        name="maturity"
                        value={formData.maturity}
                        onChange={handleChange}
                        placeholder="Enter maturity"
                      />
                      {errors.maturity && (
                        <div className="text-danger">
                          {errors.maturity}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="size">Size</label>
                      <input
                        type="text"
                        className="form-control"
                        id="size"
                        name="size"
                        value={formData.size}
                        onChange={handleChange}
                        placeholder="Enter size"
                      />
                      {errors.size && (
                        <div className="text-danger">
                          {errors.size}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="isin">Isin</label>
                      <input
                        type="text"
                        className="form-control"
                        id="isin"
                        name="isin"
                        value={formData.isin}
                        onChange={handleChange}
                        placeholder="Enter isin"
                      />
                      {errors.isin && (
                        <div className="text-danger">
                          {errors.isin}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="zSpread">Z+ Spread</label>
                      <input
                        type="text"
                        className="form-control"
                        id="zSpread"
                        name="zSpread"
                        value={formData.zSpread}
                        onChange={handleChange}
                        placeholder="Enter zSpread"
                      />
                      {errors.zSpread && (
                        <div className="text-danger">
                          {errors.zSpread}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="trader">Trader</label>
                      <input
                        type="text"
                        className="form-control"
                        id="trader"
                        name="trader"
                        value={formData.trader}
                        onChange={handleChange}
                        placeholder="Enter trader"
                      />
                      {errors.trader && (
                        <div className="text-danger">
                          {errors.trader}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-secondary mr-2"
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-orange">
                    {id && id !== '' ? 'Update Axe' : 'Create New Axe'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAxe;
