import React, { useContext, useEffect, useMemo, useState } from "react";
import { Cookies } from "react-cookie";
import { Link, useLocation, useNavigate } from "react-router-dom";
import image from "../util/image";
import text from "../util/text";
import TimeZoneDropDown from '../components/TimeZone';
import GlobalContext from "../context/global/globalContext";
import moment from 'moment-timezone';
import { capitalizeFirstLetter } from "../util/constants";
import { LogoutModal } from "../components/Modal/LogoutModal";
import NotificationApi from "../api/notificationApi";
import bellIcon from "../assets/images/bell-icon.png"

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const globalContext = useContext(GlobalContext);
  const cookies = new Cookies();
  const notiApi = new NotificationApi();
  let cookieData = cookies.get('userInfo');

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [feedbackSubmenuOpen, setFeedbackSubmenuOpen] = useState(false);
  const [myfeedbackSubmenuOpen, setMyFeedbackSubmenuOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationsCount, setNotificationsCount] = useState(0);
  let UserId = cookieData._id;

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const res = await notiApi.getUserNotification(UserId, notificationsCount);
        if (res.success) {
          setNotifications(res.data);
          setNotificationsCount(res.count);
        }
      } catch (err) {
        console.error("Getting notification error => ", err);
      }
    };

    fetchNotifications();

    const intervalId = setInterval(fetchNotifications, 30000);
    return () => clearInterval(intervalId);
  }, [notificationsCount]);

  // useEffect(() => {
  //   getNotifications();
  // }, [])

  // const getNotifications = async () => {
  //   notiApi.getUserNotification(UserId).then((res) => {
  //     if (res.success) {
  //       setNotificationsCount(res.data.length)
  //       setNotifications(res.data);
  //     }
  //   }).catch((err) => {
  //     console.error("Getting notification error => ", err);
  //     //setIsLoading(false)
  //   });
  // }

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const toggleFeedbackSubmenu = (forMenu) => {
    if (forMenu == 'myFeedback') {
      setFeedbackSubmenuOpen(!feedbackSubmenuOpen);
      setMyFeedbackSubmenuOpen(false)
    } else if (forMenu == 'collegueFeedback') {
      setMyFeedbackSubmenuOpen(!myfeedbackSubmenuOpen)
      setFeedbackSubmenuOpen(false)
    }
  };

  const closeSubMenuItem = () => {
    setFeedbackSubmenuOpen(false);
    setMyFeedbackSubmenuOpen(false);
  }

  //console.log("notifications", notifications);

  return (
    <>
      <div className="header">
        <div className="header-left">
          <div className="menu-icon fi-list" style={{ fontSize: "30px" }} onClick={toggleSidebar}></div>

          <div className="user-info-dropdown">
            <div className="dropdown">
              <a
                className="dropdown-toggle"
                href="#"
                role="button"
              // data-toggle="dropdown"
              >
                {/* image of user */}
                {
                  cookieData.userRole !== 'master' &&
                  <span className="user-icon">
                    {
                      cookieData.photourl ?
                        <img src={cookieData.photourl} alt="" /> :
                        <img src={image.profileDefault} alt="" />
                    }
                  </span>
                }
                <span className="user-name">
                  {cookieData.userRole == 'master' ? capitalizeFirstLetter(cookieData.name) : capitalizeFirstLetter(cookieData.name)}
                  {` ${cookieData.isManager && cookieData.userRole !== 'master' ? text.showManager : ''}`}
                  <br />
                  <b>
                    {
                      cookieData.bankName && cookieData.bankName !== '' && cookieData.bankName != null ?
                        `${cookieData.bankName} - ` : ''
                    }
                    {cookieData.userRole === 'bankAdmin' ? text.banAdminRole : capitalizeFirstLetter(cookieData.userRole)}
                  </b>
                </span>
              </a>
              {/* <div className="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
          <a className="dropdown-item" href="profile.html">
            <i className="dw dw-user1" /> Profile
          </a>
          <a className="dropdown-item" href="profile.html">
            <i className="dw dw-settings2" /> Setting
          </a>
          <a className="dropdown-item" href="faq.html">
            <i className="dw dw-help" /> Help
          </a>
          <a className="dropdown-item" href="login.html">
            <i className="dw dw-logout" /> Log Out
          </a>
        </div> */}
            </div>
            {/* <h3 className="user-welcome font-weight-medium"> Welcome Back</h3> */}
          </div>

          {/* <div className="header-search">
            <form>
              <div className="form-group mb-0">
                <i className="dw dw-search2 search-icon" />
                <input
                  type="text"
                  className="form-control search-input"
                  placeholder="Search Here"
                />
                {/* <div className="dropdown">
                  <a
                    className="dropdown-toggle no-arrow"
                    href="#"
                    role="button"
                    data-toggle="dropdown"
                  >
                    <i className="ion-arrow-down-c" />
                  </a>
                  <div className="dropdown-menu dropdown-menu-right">
                    <div className="form-group row">
                      <label className="col-sm-12 col-md-2 col-form-label">
                        From
                      </label>
                      <div className="col-sm-12 col-md-10">
                        <input
                          className="form-control form-control-sm form-control-line"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-12 col-md-2 col-form-label">To</label>
                      <div className="col-sm-12 col-md-10">
                        <input
                          className="form-control form-control-sm form-control-line"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-12 col-md-2 col-form-label">
                        Subject
                      </label>
                      <div className="col-sm-12 col-md-10">
                        <input
                          className="form-control form-control-sm form-control-line"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="text-right">
                      <button className="btn btn-primary">Search</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div> */}

          {/* <div className="menu-icon dw dw-menu" />
          <div
            className="search-toggle-icon dw dw-search2"
            data-toggle="header_search"
          /> */}

        </div>
        <div className="header-right">
          <div className="header-datetime">
            <div className="test">
              <TimeZoneDropDown />
            </div>
            <span className="d-flex justify-content-sm-center justify-content-md-end center-xs pt-2">
              <span className="datetime-container">
                <span className="time">{moment().tz(globalContext.timeZoneValue).format('hh:mmA')}</span>
                <span className="separator">|</span>
                <span className="date">{moment().tz(globalContext.timeZoneValue).format('DD.MM.YYYY')}</span>
              </span>
            </span>
          </div>
          {/* <div className="header-search">
            <form>
              <div className="form-group mb-0">
                <i className="dw dw-search2 search-icon" />
                <input
                  type="text"
                  className="form-control search-input"
                  placeholder="Search Here"
                />
              </div>
            </form>
          </div>
          <div className="search-toggle-icon dw dw-search2" data-toggle="header_search"></div> */}
          <div className="user-notification">
            <div className="dropdown">
              <a
                className="dropdown-toggle no-arrow"
                href="#"
                role="button"
                data-toggle="dropdown"
              >
                <i className="icon-copy dw dw-notification" />
                <span className="badge notification-active" />
              </a>
              <div className="dropdown-menu dropdown-menu-right">
                <div className="notification-list mx-h-300 customscroll">
                  {notifications?.length === 0 ? (
                    <ul>
                      <li>
                        <p className="text-center">No data found</p>
                      </li>
                    </ul>

                  ) : (
                    <ul>
                      {notifications?.map((notification, index) => (
                        <li key={index}  className='border-bottom'>
                          <Link to="notifications">
                            {/* <img src={bellIcon} alt="" /> */}
                            <h3>{notification.createdBy}</h3>
                            <p>{notification.message}</p>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* Sidebar */}
      <div className={`left-side-bar ${sidebarOpen ? 'open' : ''}`}>
        <div className="brand-logo">
          <a href="#">
            <img src={image.logo} alt="" className="dark-logo" />
            <img src={image.logo} alt="" className="light-logo" />
          </a>
          <div className="close-sidebar" data-toggle="left-sidebar-close" onClick={toggleSidebar}>
            <i className="ion-close-round"></i>
          </div>
        </div>
        <div className="menu-block customscroll">
          <div className="sidebar-menu">
            <ul id="accordion-menu" className="accordion-top1">

              {/* for master admin */}
              {
                cookieData.userRole == 'master' && <>
                  <li className="p-6" data-toggle="left-sidebar-close">
                    <Link
                      className={location.pathname == "/manageBanks" ? "dropdown-toggle no-arrow active" : "dropdown-toggle no-arrow"}
                      to="/manageBanks">
                      <span className="micon icon-copy fa fa-bank"></span>
                      <span className="mtext">{text.sidebarManageBank}</span>
                    </Link>
                  </li>
                  <li className="p-6" data-toggle="left-sidebar-close">
                    <Link
                      className={location.pathname == "/manageTeamMember" ? "dropdown-toggle no-arrow active" : "dropdown-toggle no-arrow"}
                      to="/manageTeamMember">
                      <span className="micon icon-copy fa fa-users"></span>
                      <span className="mtext">{text.sidebarManageTeamManager}</span>
                    </Link>
                  </li>

                  {/* Assign Team Member */}
                  <li className="p-6" data-toggle="left-sidebar-close">
                    <Link
                      className={location.pathname == "/assignTeamMember" ? "dropdown-toggle no-arrow active" : "dropdown-toggle no-arrow"}
                      to="/assignTeamMember">
                      <span className="micon icon-copy fa fa-user-plus"></span>
                      <span className="mtext">{text.sidebarAssignTeamManager}</span>
                    </Link>
                  </li>
                </>
              }

              {/* for trader */}
              {
                cookieData.userRole == 'trader' && <>

                  <li className="p-6" data-toggle="left-sidebar-close" onClick={() => { closeSubMenuItem(); setSidebarOpen(false) }}>
                    <Link
                      className={location.pathname == "/manageAxe" ? "dropdown-toggle no-arrow active" : "dropdown-toggle no-arrow"}
                      to="/manageAxe">
                      <span className="micon icon-copy fa fa-navicon"></span>
                      <span className="mtext">Axe Management</span>
                    </Link>
                  </li>

                  {/* Axe Tool */}
                  <li className="p-6" data-toggle="left-sidebar-close" onClick={() => { closeSubMenuItem(); setSidebarOpen(false) }}>
                    <Link
                      className={location.pathname == "/manageAxeTool" ? "dropdown-toggle no-arrow active" : "dropdown-toggle no-arrow"}
                      to="/manageAxeTool">
                      <span className="micon icon-copy fa fa-navicon"></span>
                      <span className="mtext">Axe Tool</span>
                    </Link>
                  </li>

                  {/* My Feedback */}
                  <li className={`dropdown ${feedbackSubmenuOpen ? 'show' : ''}`} data-toggle="left-sidebar-close" onClick={() => { toggleFeedbackSubmenu('myFeedback'); setSidebarOpen(false) }}>
                    <Link to="/manageFeedback" className={location.pathname.startsWith("/manageFeedback") ? "dropdown-toggle active" : "dropdown-toggle"}>
                      <span className="micon icon-copy fa fa-navicon"></span>
                      <span className="mtext">My Feedback</span>
                    </Link>
                    <ul className="submenu" style={{ display: feedbackSubmenuOpen ? 'block' : 'none' }}>
                      <li onClick={(e) => { e.preventDefault(); e.stopPropagation(); setFeedbackSubmenuOpen(true); setSidebarOpen(false) }} >
                        <Link to="/manageFeedback/primary" className={location.pathname == "/manageFeedback/primary" ? " active" : ""}>Primary</Link>
                      </li>
                      <li onClick={(e) => { e.preventDefault(); e.stopPropagation(); setFeedbackSubmenuOpen(true); setSidebarOpen(false) }} >
                        <Link to="/manageFeedback/axes" className={location.pathname == "/manageFeedback/axes" ? " active" : ""}>Axes</Link>
                      </li>
                      <li onClick={(e) => { e.preventDefault(); e.stopPropagation(); setFeedbackSubmenuOpen(true); setSidebarOpen(false) }} >
                        <Link to="/manageFeedback/general" className={location.pathname == "/manageFeedback/general" ? " active" : ""}>General</Link>
                      </li>
                    </ul>
                  </li>

                  {/* Collegue Feedback */}
                  <li className={`dropdown ${myfeedbackSubmenuOpen ? 'show' : ''}`} data-toggle="left-sidebar-close" onClick={() => { toggleFeedbackSubmenu('collegueFeedback'); setSidebarOpen(false) }}>
                    <Link to="/manage-colleague-feedback" className={location.pathname.startsWith("/manage-colleague-feedback") ? "dropdown-toggle active" : "dropdown-toggle"}>
                      <span className="micon icon-copy fa fa-navicon"></span>
                      <span className="mtext">Colleagues' Feedback</span>
                    </Link>
                    <ul className="submenu" style={{ display: myfeedbackSubmenuOpen ? 'block' : 'none' }}>
                      <li onClick={(e) => { e.preventDefault(); e.stopPropagation(); setMyFeedbackSubmenuOpen(true); setSidebarOpen(false) }} >
                        <Link to="/manage-colleague-feedback/primary" className={location.pathname == "/manage-colleague-feedback/primary" ? " active" : ""}>Primary</Link>
                      </li>
                      <li onClick={(e) => { e.preventDefault(); e.stopPropagation(); setMyFeedbackSubmenuOpen(true); setSidebarOpen(false) }} >
                        <Link to="/manage-colleague-feedback/axes" className={location.pathname == "/manage-colleague-feedback/axes" ? " active" : ""}>Axes</Link>
                      </li>
                      <li onClick={(e) => { e.preventDefault(); e.stopPropagation(); setMyFeedbackSubmenuOpen(true); setSidebarOpen(false) }} >
                        <Link to="/manage-colleague-feedback/general" className={location.pathname == "/manage-colleague-feedback/general" ? " active" : ""}>General</Link>
                      </li>
                    </ul>
                  </li>

                  {/* manage Team member */}
                  {
                    cookieData.userRole == 'trader' && cookieData.isManager &&
                    <li className="p-6" data-toggle="left-sidebar-close" data-option="off" onClick={() => closeSubMenuItem()}>
                      <Link
                        className={location.pathname.includes("/manageTeamMember") ? "dropdown-toggle no-arrow active" : "dropdown-toggle no-arrow"}
                        to={`/manageTeamMember/${cookieData.AssociatedWith}`}>
                        <span className="micon icon-copy fa fa-users"></span>
                        <span className="mtext">{text.sidebarManageTeamManager}</span>
                      </Link>
                    </li>
                  }
                </>
              }

              {
                cookieData.userRole == 'sales' && <>
                  {/* Axe Tool */}
                  <li className="p-6" data-toggle="left-sidebar-close" onClick={() => { closeSubMenuItem(); setSidebarOpen(false) }}>
                    <Link
                      className={location.pathname == "/manageAxeTool" ? "dropdown-toggle no-arrow active" : "dropdown-toggle no-arrow"}
                      to="/manageAxeTool">
                      <span className="micon icon-copy fa fa-navicon"></span>
                      <span className="mtext">Axe Tool</span>
                    </Link>
                  </li>

                  {/* My Feedback */}
                  <li className={`dropdown ${feedbackSubmenuOpen ? 'show' : ''}`} data-toggle="left-sidebar-close" onClick={() => { toggleFeedbackSubmenu('myFeedback'); setSidebarOpen(false) }}>
                    <Link to="/manageFeedback" className={location.pathname.startsWith("/manageFeedback") ? "dropdown-toggle active" : "dropdown-toggle"}>
                      <span className="micon icon-copy fa fa-navicon"></span>
                      <span className="mtext">My Feedback</span>
                    </Link>
                    <ul className="submenu" style={{ display: feedbackSubmenuOpen ? 'block' : 'none' }}>
                      <li onClick={(e) => { e.preventDefault(); e.stopPropagation(); setFeedbackSubmenuOpen(true); setSidebarOpen(false) }} >
                        <Link to="/manageFeedback/primary" className={location.pathname == "/manageFeedback/primary" ? " active" : ""}>Primary</Link>
                      </li>
                      <li onClick={(e) => { e.preventDefault(); e.stopPropagation(); setFeedbackSubmenuOpen(true); setSidebarOpen(false) }} >
                        <Link to="/manageFeedback/axes" className={location.pathname == "/manageFeedback/axes" ? " active" : ""}>Axes</Link>
                      </li>
                      <li onClick={(e) => { e.preventDefault(); e.stopPropagation(); setFeedbackSubmenuOpen(true); setSidebarOpen(false) }} >
                        <Link to="/manageFeedback/general" className={location.pathname == "/manageFeedback/general" ? " active" : ""}>General</Link>
                      </li>
                    </ul>
                  </li>

                  {/* Collegue Feedback */}
                  <li className={`dropdown ${myfeedbackSubmenuOpen ? 'show' : ''}`} data-toggle="left-sidebar-close" onClick={() => { toggleFeedbackSubmenu('collegueFeedback'); setSidebarOpen(false) }}>
                    <Link to="/manage-colleague-feedback" className={location.pathname.startsWith("/manage-colleague-feedback") ? "dropdown-toggle active" : "dropdown-toggle"}>
                      <span className="micon icon-copy fa fa-navicon"></span>
                      <span className="mtext">Colleagues' Feedback</span>
                    </Link>
                    <ul className="submenu" style={{ display: myfeedbackSubmenuOpen ? 'block' : 'none' }}>
                      <li onClick={(e) => { e.preventDefault(); e.stopPropagation(); setMyFeedbackSubmenuOpen(true); setSidebarOpen(false) }} >
                        <Link to="/manage-colleague-feedback/primary" className={location.pathname == "/manage-colleague-feedback/primary" ? " active" : ""}>Primary</Link>
                      </li>
                      <li onClick={(e) => { e.preventDefault(); e.stopPropagation(); setMyFeedbackSubmenuOpen(true); setSidebarOpen(false) }} >
                        <Link to="/manage-colleague-feedback/axes" className={location.pathname == "/manage-colleague-feedback/axes" ? " active" : ""}>Axes</Link>
                      </li>
                      <li onClick={(e) => { e.preventDefault(); e.stopPropagation(); setMyFeedbackSubmenuOpen(true); setSidebarOpen(false) }} >
                        <Link to="/manage-colleague-feedback/general" className={location.pathname == "/manage-colleague-feedback/general" ? " active" : ""}>General</Link>
                      </li>
                    </ul>
                  </li>

                  {/* manage Team member */}
                  {
                    cookieData.isManager &&
                    <li className="p-6" data-toggle="left-sidebar-close" data-option="off" onClick={() => closeSubMenuItem()}>
                      <Link
                        className={location.pathname.includes("/manageTeamMember") ? "dropdown-toggle no-arrow active" : "dropdown-toggle no-arrow"}
                        to={`/manageTeamMember/${cookieData.AssociatedWith}`}>
                        <span className="micon icon-copy fa fa-users"></span>
                        <span className="mtext">{text.sidebarManageTeamManager}</span>
                      </Link>
                    </li>
                  }
                </>
              }

              {
                cookieData.userRole == 'syndicate' &&
                <>
                  <li className="p-6" data-toggle="left-sidebar-close" onClick={() => { closeSubMenuItem(); setSidebarOpen(false) }}>
                    <Link
                      className={location.pathname == "/manageAxe" ? "dropdown-toggle no-arrow active" : "dropdown-toggle no-arrow"}
                      to="/manageAxe">
                      <span className="micon icon-copy fa fa-navicon"></span>
                      <span className="mtext">Axe Management</span>
                    </Link>
                  </li>

                  {/* Axe Tool */}
                  <li className="p-6" data-toggle="left-sidebar-close" onClick={() => { closeSubMenuItem(); setSidebarOpen(false) }}>
                    <Link
                      className={location.pathname == "/manageAxeTool" ? "dropdown-toggle no-arrow active" : "dropdown-toggle no-arrow"}
                      to="/manageAxeTool">
                      <span className="micon icon-copy fa fa-navicon"></span>
                      <span className="mtext">Axe Tool</span>
                    </Link>
                  </li>

                  {/* My Feedback */}
                  <li className={`dropdown ${feedbackSubmenuOpen ? 'show' : ''}`} data-toggle="left-sidebar-close" onClick={() => { toggleFeedbackSubmenu('myFeedback'); setSidebarOpen(false) }}>
                    <Link to="/manageFeedback" className={location.pathname.startsWith("/manageFeedback") ? "dropdown-toggle active" : "dropdown-toggle"}>
                      <span className="micon icon-copy fa fa-navicon"></span>
                      <span className="mtext">My Feedback</span>
                    </Link>
                    <ul className="submenu" style={{ display: feedbackSubmenuOpen ? 'block' : 'none' }}>
                      <li onClick={(e) => { e.preventDefault(); e.stopPropagation(); setFeedbackSubmenuOpen(true); setSidebarOpen(false) }} >
                        <Link to="/manageFeedback/primary" className={location.pathname == "/manageFeedback/primary" ? " active" : ""}>Primary</Link>
                      </li>
                      <li onClick={(e) => { e.preventDefault(); e.stopPropagation(); setFeedbackSubmenuOpen(true); setSidebarOpen(false) }} >
                        <Link to="/manageFeedback/axes" className={location.pathname == "/manageFeedback/axes" ? " active" : ""}>Axes</Link>
                      </li>
                      <li onClick={(e) => { e.preventDefault(); e.stopPropagation(); setFeedbackSubmenuOpen(true); setSidebarOpen(false) }} >
                        <Link to="/manageFeedback/general" className={location.pathname == "/manageFeedback/general" ? " active" : ""}>General</Link>
                      </li>
                    </ul>
                  </li>

                  {/* Collegue Feedback */}
                  <li className={`dropdown ${myfeedbackSubmenuOpen ? 'show' : ''}`} data-toggle="left-sidebar-close" onClick={() => { toggleFeedbackSubmenu('collegueFeedback'); setSidebarOpen(false) }}>
                    <Link to="/manage-colleague-feedback" className={location.pathname.startsWith("/manage-colleague-feedback") ? "dropdown-toggle active" : "dropdown-toggle"}>
                      <span className="micon icon-copy fa fa-navicon"></span>
                      <span className="mtext">Colleagues' Feedback</span>
                    </Link>
                    <ul className="submenu" style={{ display: myfeedbackSubmenuOpen ? 'block' : 'none' }}>
                      <li onClick={(e) => { e.preventDefault(); e.stopPropagation(); setMyFeedbackSubmenuOpen(true); setSidebarOpen(false) }} >
                        <Link to="/manage-colleague-feedback/primary" className={location.pathname == "/manage-colleague-feedback/primary" ? " active" : ""}>Primary</Link>
                      </li>
                      <li onClick={(e) => { e.preventDefault(); e.stopPropagation(); setMyFeedbackSubmenuOpen(true); setSidebarOpen(false) }} >
                        <Link to="/manage-colleague-feedback/axes" className={location.pathname == "/manage-colleague-feedback/axes" ? " active" : ""}>Axes</Link>
                      </li>
                      <li onClick={(e) => { e.preventDefault(); e.stopPropagation(); setMyFeedbackSubmenuOpen(true); setSidebarOpen(false) }} >
                        <Link to="/manage-colleague-feedback/general" className={location.pathname == "/manage-colleague-feedback/general" ? " active" : ""}>General</Link>
                      </li>
                    </ul>
                  </li>

                  {/* manage Team member */}
                  {
                    cookieData.isManager &&
                    <li className="p-6" data-toggle="left-sidebar-close" data-option="off" onClick={() => closeSubMenuItem()}>
                      <Link
                        className={location.pathname.includes("/manageTeamMember") ? "dropdown-toggle no-arrow active" : "dropdown-toggle no-arrow"}
                        to={`/manageTeamMember/${cookieData.AssociatedWith}`}>
                        <span className="micon icon-copy fa fa-users"></span>
                        <span className="mtext">{text.sidebarManageTeamManager}</span>
                      </Link>
                    </li>
                  }
                </>
              }

              {
                cookieData.userRole == 'bankAdmin' &&
                <>
                  {/* Axe Tool */}
                  <li className="p-6" data-toggle="left-sidebar-close" onClick={() => { closeSubMenuItem(); setSidebarOpen(false) }}>
                    <Link
                      className={location.pathname == "/manageAxeTool" ? "dropdown-toggle no-arrow active" : "dropdown-toggle no-arrow"}
                      to="/manageAxeTool">
                      <span className="micon icon-copy fa fa-navicon"></span>
                      <span className="mtext">Axe Tool</span>
                    </Link>
                  </li>

                  {/* My Feedback */}
                  <li className={`dropdown ${feedbackSubmenuOpen ? 'show' : ''}`} data-toggle="left-sidebar-close" onClick={() => { toggleFeedbackSubmenu('myFeedback'); setSidebarOpen(false) }}>
                    <Link to="/manageFeedback" className={location.pathname.startsWith("/manageFeedback") ? "dropdown-toggle active" : "dropdown-toggle"}>
                      <span className="micon icon-copy fa fa-navicon"></span>
                      <span className="mtext">My Feedback</span>
                    </Link>
                    <ul className="submenu" style={{ display: feedbackSubmenuOpen ? 'block' : 'none' }}>
                      <li onClick={(e) => { e.preventDefault(); e.stopPropagation(); setFeedbackSubmenuOpen(true); setSidebarOpen(false) }} >
                        <Link to="/manageFeedback/primary" className={location.pathname == "/manageFeedback/primary" ? " active" : ""}>Primary</Link>
                      </li>
                      <li onClick={(e) => { e.preventDefault(); e.stopPropagation(); setFeedbackSubmenuOpen(true); setSidebarOpen(false) }} >
                        <Link to="/manageFeedback/axes" className={location.pathname == "/manageFeedback/axes" ? " active" : ""}>Axes</Link>
                      </li>
                      <li onClick={(e) => { e.preventDefault(); e.stopPropagation(); setFeedbackSubmenuOpen(true); setSidebarOpen(false) }} >
                        <Link to="/manageFeedback/general" className={location.pathname == "/manageFeedback/general" ? " active" : ""}>General</Link>
                      </li>
                    </ul>
                  </li>

                  {/* Collegue Feedback */}
                  <li className={`dropdown ${myfeedbackSubmenuOpen ? 'show' : ''}`} data-toggle="left-sidebar-close" onClick={() => { toggleFeedbackSubmenu('collegueFeedback'); setSidebarOpen(false) }}>
                    <Link to="/manage-colleague-feedback" className={location.pathname.startsWith("/manage-colleague-feedback") ? "dropdown-toggle active" : "dropdown-toggle"}>
                      <span className="micon icon-copy fa fa-navicon"></span>
                      <span className="mtext">Colleagues' Feedback</span>
                    </Link>
                    <ul className="submenu" style={{ display: myfeedbackSubmenuOpen ? 'block' : 'none' }}>
                      <li onClick={(e) => { e.preventDefault(); e.stopPropagation(); setMyFeedbackSubmenuOpen(true); setSidebarOpen(false) }} >
                        <Link to="/manage-colleague-feedback/primary" className={location.pathname == "/manage-colleague-feedback/primary" ? " active" : ""}>Primary</Link>
                      </li>
                      <li onClick={(e) => { e.preventDefault(); e.stopPropagation(); setMyFeedbackSubmenuOpen(true); setSidebarOpen(false) }} >
                        <Link to="/manage-colleague-feedback/axes" className={location.pathname == "/manage-colleague-feedback/axes" ? " active" : ""}>Axes</Link>
                      </li>
                      <li onClick={(e) => { e.preventDefault(); e.stopPropagation(); setMyFeedbackSubmenuOpen(true); setSidebarOpen(false) }} >
                        <Link to="/manage-colleague-feedback/general" className={location.pathname == "/manage-colleague-feedback/general" ? " active" : ""}>General</Link>
                      </li>
                    </ul>
                  </li>

                  {/* manage Team member */}
                  {
                    cookieData.isManager &&
                    <li className="p-6" data-toggle="left-sidebar-close" data-option="off" onClick={() => closeSubMenuItem()}>
                      <Link
                        className={location.pathname.includes("/manageTeamMember") ? "dropdown-toggle no-arrow active" : "dropdown-toggle no-arrow"}
                        to={`/manageTeamMember/${cookieData.AssociatedWith}`}>
                        <span className="micon icon-copy fa fa-users"></span>
                        <span className="mtext">{text.sidebarManageTeamManager}</span>
                      </Link>
                    </li>
                  }

                  {/* Assign Team Member */}
                  <li className="p-6" data-toggle="left-sidebar-close">
                    <Link
                      className={location.pathname == "/assignTeamMember" ? "dropdown-toggle no-arrow active" : "dropdown-toggle no-arrow"}
                      to="/assignTeamMember">
                      <span className="micon icon-copy fa fa-user-plus"></span>
                      <span className="mtext">{text.sidebarAssignTeamManager}</span>
                    </Link>
                  </li>
                </>
              }

              {/* Change Password */}
              <li className="p-6" data-toggle="left-sidebar-close" onClick={() => closeSubMenuItem()}>
                <Link
                  className={location.pathname == "/changePassword" ? "dropdown-toggle no-arrow active" : "dropdown-toggle no-arrow"}
                  to="/changePassword">
                  <span className="micon icon-copy fa fa-key" />
                  <span className="mtext">{text.sidebarChangePassword}</span>
                </Link>
              </li>

              {
                cookieData.userRole !== 'master' &&
                <li className="p-6" data-toggle="left-sidebar-close" onClick={() => closeSubMenuItem()}>
                  <Link
                    className={location.pathname == "/profile" ? "dropdown-toggle no-arrow active" : "dropdown-toggle no-arrow"}
                    to="/profile">
                    <span className="micon icon-copy fa fa-user-circle-o"></span><span className="mtext">My Profile</span>
                  </Link>
                </li>
              }
            </ul>

            {/* for logout button */}
            <ul id="accordion-menu" className="accordion-bottom">
              <li className="p-6 dropdown">
                <a
                  className={`${location.pathname == "/logout" ? "dropdown-toggle no-arrow active" : "dropdown-toggle no-arrow"} cursor `}
                  data-toggle="modal" data-target="#confirmation-modal"
                >
                  <span className="micon icon-copy ion-android-exit" />
                  <span className="mtext">Log out</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={`mobile-menu-overlay ${sidebarOpen ? 'show' : ''}  `}></div>
      {/* logout modal */}
      <LogoutModal />
    </>

  );
};

export default Header;
