import React from 'react'
import text from '../../util/text'

export const DeleteModal = ({ handleDelete }) => {
  return (
    <>
      <div className="modal fade deleteModal" id="alert-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body text-center p-3">
              <h3 className="mb-15"><i className="fa fa-exclamation-triangle"></i> {text.deleteHeader}</h3>
              <p>{text.deleteMessage}</p>
              <button type="button" className="btn btn-light" data-dismiss="modal" onClick={() => handleDelete()} >{text.ok}</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
