import React, { useEffect, useContext } from "react";
import AuthContext from "../../context/auth/authContext";
const AutoLogout = () => {
    const setUser = useContext(AuthContext);

    const startLogginOut = () => {
        let  logout = setUser.update("logout");
    }

    return(
        <div style={{
            zIndex: 1050,
            position: "fixed",
            height: "100vh",
            width: "100vw",
            background: "#000",
            top: 0,
            left: 0,
            opacity: 0.5,
        }}>
            {/* <span style={{
                display: "block",
                marginTop: "20%",
                marginLeft: "46%"
            }}>Logging out</span> */}
            {
                startLogginOut()
            }
        </div>
    )

}

export default AutoLogout;