import React, { useEffect, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BankApi from "../../../api/bankApi";
import { toast } from "react-toastify";
import text from "../../../util/text";
import DataTable from "../../../components/DataTable/DataTable";
import TeamApi from "../../../api/memberApi";
import IndeterminateCheckbox from "../../../components/DataTable/IndeterminateCheckbox";
import { Loader } from "../../../components/Loader";

const AddTeam = () => {
  const { id } = useParams();
  const api = new TeamApi();
  const bankApi = new BankApi();
  const navigate = useNavigate();
  const [columnVisibility, setColumnVisibility] = useState({});
  const [tableInstance, setTableInstance] = useState(null);
  const [formData, setFormData] = useState({
    _id: "",
    bankId: "",
    role: "",
    managerId: "",
    selectedUserData: null, // New state to hold selected user data
  });

  const [users, setUsers] = useState([]);
  const [banks, setBanks] = useState([]);
  const [managers, setManagers] = useState([]);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false)
  const [submitBtnStatus, setSubmitBtnStatus] = useState(false)

  useEffect(() => {
    if (formData.bankId && formData.role) {
      setIsLoading(true)
      api
        .getUsersWithoutManager({
          bankId: formData.bankId,
          role: formData.role,
        })
        .then((res) => {
          if (res.success) {
            setUsers(res.data);
            setIsLoading(false)
          } else {
            toast.error(res.message);
            setIsLoading(false)
          }
        })
        .catch((err) => {
          console.error("Fetch all banks error => ", err);
          setIsLoading(false)
        });
    } else {
      setUsers([]);
    }
  }, [formData.bankId, formData.role]);

  useEffect(() => {
    bankApi
      .getBanks()
      .then((res) => {
        if (res.success) {
          let selectedBank = res.data.filter(item => item.status == 'active').sort((a, b) => a.bankName.localeCompare(b.bankName))
          setBanks(selectedBank);
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        console.error("Fetch all banks error => ", err);
      });
  }, []);

  useEffect(() => {
    // Fetch managers when bankId or role changes
    if (formData.bankId && formData.role) {
      api
        .getRoleRelatedManagers({
          bankId: formData.bankId,
          role: formData.role,
        })
        .then((res) => {
          if (res.success) {
            setManagers(res.data);
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => {
          setManagers([]);
          console.error("Fetch all banks error => ", err);
        });
    } else {
      setManagers([]);
    }
  }, [formData.bankId, formData.role]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));

    // Clear errors for the field being edited
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", // Clear error for this field
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newErrors = {};

    if (!formData.bankId) newErrors.bankId = "Bank is required";
    if (!formData.role) newErrors.role = "Role is required";
    if (!formData.managerId) newErrors.managerId = "Manager is required";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setSubmitBtnStatus(true)
      // Merge selected user data into formData
      const selectedRows =
        tableInstance && tableInstance.getSelectedRowModel().flatRows;

      let sendData;
      if (selectedRows.length > 0) {
        const selectedUserDatas = selectedRows.map((row) => row.original);
        sendData = { ...formData };
        sendData.selectedUserData = selectedUserDatas;
      }

      api
        .saveTeamMembers(sendData)
        .then((res) => {
          if (res.success) {
            toast.success(res.message);
            navigate("/manageTeamMember");
            setSubmitBtnStatus(false)
          } else {
            toast.error(res.message);
            setErrors({});
            setSubmitBtnStatus(false)
          }
        })
        .catch((err) => {
          console.error("Add team manager error => ", err);
          setErrors({});
        });
    }
  };

  const data = useMemo(() => users, [users]);

  const bankColumns = [
    {
      id: "select",
      header: ({ table }) => (
        <div className="px-1">
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        </div>
      ),
      cell: ({ row }) => (
        <div className="px-1">
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      ),
    },
    {
      header: "User Name",
      accessorKey: "name",
    },
    {
      header: "E-mail",
      accessorKey: "email",
    },
  ];

  return (
    <>
      <div className="min-height-200px">
        <div className="py-4 d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
          <h5 className="text-black mb-3 mb-md-0">{text.addUpdateTeam}</h5>
          {/* <span
            className="text-black mb-3 mb-md-0 cursor"
            onClick={() => navigate(-1)}
          >
            <span className="icon-copy ti-angle-left" />
            {text.backText}
          </span> */}
        </div>
        <div className="card-box mb-30">
          <div className="py-3 d-flex">
            <div className="container">
              <form onSubmit={handleSubmit}>
                <div className="row px-4">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="bankId">Select Bank</label>
                      <select
                        className="form-control"
                        id="bankId"
                        name="bankId"
                        value={formData.bankId}
                        onChange={handleChange}
                      >
                        <option value="">Select Bank</option>
                        {banks.map((bank) => (
                          <option key={bank._id} value={bank._id}>
                            {bank.bankName}
                          </option>
                        ))}
                      </select>
                      {errors.bankId && (
                        <div className="text-danger">{errors.bankId}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="role">Select Role</label>
                      <select
                        className="form-control"
                        id="role"
                        name="role"
                        value={formData.role}
                        onChange={handleChange}
                      >
                        <option value="">Select Role</option>
                        <option value="syndicate">Syndicate</option>
                        <option value="sales">Sales</option>
                        <option value="trader">Trader</option>
                      </select>
                      {errors.role && (
                        <div className="text-danger">{errors.role}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="managerId">Select Manager</label>
                      <select
                        className="form-control"
                        id="managerId"
                        name="managerId"
                        value={formData.managerId}
                        onChange={handleChange}
                        disabled={!formData.bankId || !formData.role}
                      >
                        <option value="">Select Manager</option>
                        {managers.map((manager) => (
                          <option key={manager._id} value={manager._id}>
                            {manager.name}
                          </option>
                        ))}
                      </select>
                      {errors.managerId && (
                        <div className="text-danger">{errors.managerId}</div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-end px-4">
                  {/* <button
                    type="button"
                    className="btn btn-secondary mr-4"
                    onClick={() => navigate(-1)}
                  >
                    {text.cancel}
                  </button> */}
                  <button type="submit" className="btn btn-orange"
                    disabled={submitBtnStatus}>
                    {id && id !== "" ? text.updateManager : text.addManager}
                  </button>
                </div>
              </form>

              <div className="px-4">
              {
                data && data.length ?
                  <DataTable
                    data={data}
                    columns={bankColumns}
                    columnVisibility={columnVisibility}
                    setColumnVisibility={setColumnVisibility}
                    setTableInstance={setTableInstance}
                    isLoading={isLoading}
                  /> :
                  <div className="row d-flex align-content-center justify-content-center py-5">
                    <h5>{text.forAssignTeamOnly}</h5>
                  </div>
              }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTeam;
