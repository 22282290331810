import React from "react";

const Dashboard = () => {
	return (
		<>
			<div className="card-box pd-20 height-100-p mb-30">
				<div className="row">
					<div className="col-12 py-4">
						<h2>Content...</h2>
						<h5>Scroll down to see the Navbar stick</h5>
						<p>
							Sriracha biodiesel taxidermy organic post-ironic, Intelligentsia
							salvia mustache 90's code editing brunch. Butcher polaroid VHS art
							party, hashtag Brooklyn deep v PBR narwhal sustainable mixtape swag
							wolf squid tote bag. Tote bag cronut semiotics, raw denim deep v
							taxidermy messenger bag. Tofu YOLO Etsy, direct trade ethical Odd
							Future jean shorts paleo. Forage Shoreditch tousled aesthetic irony,
							street art organic Bushwick artisan cliche semiotics ugh synth
							chillwave meditation. Shabby chic lomo plaid vinyl chambray Vice. Vice
							sustainable cardigan, Williamsburg master cleanse hella DIY 90's blog.
						</p>
						<hr />
						<p>
							Ethical Kickstarter PBR asymmetrical lo-fi. Dreamcatcher street art
							Carles, stumptown gluten-free Kickstarter artisan Wes Anderson wolf
							pug. Godard sustainable you probably haven't heard of them, vegan
							farm-to-table Williamsburg slow-carb readymade disrupt deep v.
							Meggings seitan Wes Anderson semiotics, cliche American Apparel
							whatever. Helvetica cray plaid, vegan brunch Banksy leggings +1 direct
							trade. Wayfarers codeply PBR selfies. Banh mi McSweeney's Shoreditch
							selfies, forage fingerstache food truck occupy YOLO Pitchfork fixie
							iPhone fanny pack art party Portland.
						</p>
						<hr />
						<p>
							Sriracha biodiesel taxidermy organic post-ironic, Intelligentsia
							salvia mustache 90's code editing brunch. Butcher polaroid VHS art
							party, hashtag Brooklyn deep v PBR narwhal sustainable mixtape swag
							wolf squid tote bag. Tote bag cronut semiotics, raw denim deep v
							taxidermy messenger bag. Tofu YOLO Etsy, direct trade ethical Odd
							Future jean shorts paleo. Forage Shoreditch tousled aesthetic irony,
							street art organic Bushwick artisan cliche semiotics ugh synth
							chillwave meditation. Shabby chic lomo plaid vinyl chambray Vice. Vice
							sustainable cardigan, Williamsburg master cleanse hella DIY 90's blog.
						</p>
						<p>
							Ethical Kickstarter PBR asymmetrical lo-fi. Dreamcatcher street art
							Carles, stumptown gluten-free Kickstarter artisan Wes Anderson wolf
							pug. Godard sustainable you probably haven't heard of them, vegan
							farm-to-table Williamsburg slow-carb readymade disrupt deep v.
							Meggings seitan Wes Anderson semiotics, cliche American Apparel
							whatever. Helvetica cray plaid, vegan brunch Banksy leggings +1 direct
							trade. Wayfarers codeply PBR selfies. Banh mi McSweeney's Shoreditch
							selfies, forage fingerstache food truck occupy YOLO Pitchfork fixie
							iPhone fanny pack art party Portland.
						</p>
						<hr />
					</div>
				</div>
				{/*  <div className="row align-items-center">
              <div className="col-md-4">
                <img src="vendors/images/banner-img.png" alt="" />
              </div>
              <div className="col-md-8">
                <h4 className="font-20 weight-500 mb-10 text-capitalize">
                  Welcome back{" "}
                  <div className="weight-600 font-30 text-blue">
                    Johnny Brown!
                  </div>
                </h4>
                <p className="font-18 max-width-600">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Unde
                  hic non repellendus debitis iure, doloremque assumenda. Autem
                  modi, corrupti, nobis ea iure fugiat, veniam non quaerat
                  mollitia animi error corporis.
                </p>
              </div>
            </div> */}
			</div>
		</>
	);
};

export default Dashboard;
