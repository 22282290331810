import React, { useCallback, useContext, useMemo, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import DataTableEditable from "../../../components/DataTable/DataTableEditable";
import { useEffect } from "react";
import FeedbackApi from "../../../api/feedbackApi";
import { toast } from "react-toastify";
import { DeleteModal } from "../../../components/Modal/DeleteModal";
import { AddtoCollaegueModal } from "../../../components/Modal/AddtoCollaegueModal";
import text from '../../../util/text';
import { capitalizeFirstLetter, sidebarModule } from '../../../util/constants';
import { Popover, Overlay } from "react-bootstrap";
import IndeterminateCheckbox from "../../../components/DataTable/IndeterminateCheckbox";
import DropdownCell from "../../../components/DataTable/DropdownCell";
import GlobalContext from "../../../context/global/globalContext";
import { timeZoneChange, formatDate } from "../../../util/constants";
import { utils, writeFile } from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Cookies } from "react-cookie";
import TextAreaCell from "../../../components/DataTable/TextAreaCell";
import DropdownDailyAndAxe from "../../../components/DataTable/DropdownDailyAndAxe";
import DropdownFeedbackTypeCell from "../../../components/DataTable/DropdownFeedbackTypeCell";

const Index = () => {
  const globalContext = useContext(GlobalContext);
  const api = new FeedbackApi();
  const navigate = useNavigate();
  const location = useLocation();
  const [feedbacks, setFeedbacks] = useState([]);
  const [showPopover, setShowPopover] = useState(false);
  const [target, setTarget] = useState(null);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [tableInstance, setTableInstance] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [addTocolleagueModal, setAddTocolleagueModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [type, setType] = useState(null);
  const [selectedCol, setSelectedCol] = useState([]);
  const [checkboxState, setCheckboxState] = useState({});
  const [errors, setErrors] = useState({});
  const [currentPage, setCurrentPage] = useState(1); // Track current page
  const [excCols, setExcCols] = useState([]);
  const [renderCols, setRenderCols] = useState([]);
  const textAreaRef = useRef(null);
  const cookies = new Cookies();
  let cookieData = cookies.get('userInfo');
  const popoverRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [newRow, setNewRow] = useState({});
  const [saveBtnStatus, setSaveBtnStatus] = useState(true);

  // Update lastSegment based on the URL path segments
  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    const newLastSegment = pathSegments[pathSegments.length - 1];
    setType(newLastSegment);
  }, [location.pathname]);

  // Fetch feedbacks whenever lastSegment changes
  useEffect(() => {
    if (type) {
      getfeedbacks(type);
    }
  }, [type]);

  const getfeedbacks = async (feedbackType) => {
    try {
      setIsLoading(true);
      let sendObj = {
        feedbackType: feedbackType,
        associatedWith: cookieData.AssociatedWith,
        userId: cookieData._id,
      };
      const res = await api.getFeedbacks(sendObj);
      if (res.success) {
        setFeedbacks(res.data);
        setIsLoading(false);
      }
    } catch (err) {
      console.error("Getting feedbacks error => ", err);
      setIsLoading(false);
    }
  };

  // Function to check if a value is valid (not undefined, null, or empty)
  const isValidValue = (value) => value !== undefined && value !== null && value !== '';

  const returnMiddlecopyValue = (type, row) => {
    const sideTypeMap = {
      weBuy: 'We buy',
      weSell: 'We sell',
      clientBuys: 'Client buys',
      clientSells: 'Client sells'
    };

    const formattedDirection = sideTypeMap[row.direction] || row.direction;
    let value = ''
    if (type === 'primary') {
      if (isValidValue(row.client) && columnVisibility.client) value += `${isValidValue(row.title) ? ` - ${row.client}` : `${row.client}`}`;
      if (isValidValue(row.bond) && columnVisibility.bond) value += ` - ${row.bond}`;
      if (isValidValue(row.sector) && columnVisibility.sector) value += `/${row.sector}`;
      if (isValidValue(row.clientsTargetLevel) && columnVisibility.clientsTargetLevel) value += ` - ${row.clientsTargetLevel}`;
      if (isValidValue(row.clientsAmount) && columnVisibility.clientsAmount) value += ` - ${row.clientsAmount}`;
    } else if (type === 'axes') {
      if (isValidValue(row.client) && columnVisibility.client) value += `${isValidValue(row.title) ? ` - ${row.client}` : `${row.client}`}`;
      // below is new value inserted in case of axes
      if (isValidValue(row.direction) && columnVisibility.direction) value += ` - ${formattedDirection}`;

      if (isValidValue(row.bond) && columnVisibility.bond) value += ` - ${row.bond}`;
      if (isValidValue(row.sector) && columnVisibility.sector) value += `/${row.sector}`;
      if (isValidValue(row.interest) && columnVisibility.interest) value += ` - ${row.interest}`;
      if (isValidValue(row.clientsTargetLevel) && columnVisibility.clientsTargetLevel) value += ` - ${row.clientsTargetLevel}`;
      if (isValidValue(row.clientsAmount) && columnVisibility.clientsAmount) value += ` - ${row.clientsAmount}`;
    } else if (type === 'general') {
      if (isValidValue(row.client) && columnVisibility.client) value += `${isValidValue(row.title) ? ` - ${row.client}` : `${row.client}`}`;
    } else {
      if (row.feedbackType === 'Primary') {
        if (isValidValue(row.client) && columnVisibility.client) value += `${isValidValue(row.title) ? ` - ${row.client}` : `${row.client}`}`;
        if (isValidValue(row.bond) && columnVisibility.bond) value += ` - ${row.bond}`;
        if (isValidValue(row.sector) && columnVisibility.sector) value += `/${row.sector}`;
        if (isValidValue(row.clientsTargetLevel) && columnVisibility.clientsTargetLevel) value += ` - ${row.clientsTargetLevel}`;
        if (isValidValue(row.clientsAmount) && columnVisibility.clientsAmount) value += ` - ${row.clientsAmount}`;
      } else if (row.feedbackType === 'Axes') {
        if (isValidValue(row.client) && columnVisibility.client) value += `${isValidValue(row.title) ? ` - ${row.client}` : `${row.client}`}`;
        // below is new value inserted in case of axes
        if (isValidValue(row.direction) && columnVisibility.direction) value += ` - ${formattedDirection}`;

        if (isValidValue(row.bond) && columnVisibility.bond) value += ` - ${row.bond}`;
        if (isValidValue(row.sector) && columnVisibility.sector) value += `/${row.sector}`;
        if (isValidValue(row.clientsTargetLevel) && columnVisibility.clientsTargetLevel) value += ` - ${row.clientsTargetLevel}`;
        if (isValidValue(row.clientsAmount) && columnVisibility.clientsAmount) value += ` - ${row.clientsAmount}`;
      } else if (row.feedbackType === 'General') {
        if (isValidValue(row.client) && columnVisibility.client) value += `${isValidValue(row.title) ? ` - ${row.client}` : `${row.client}`}`;
      }
    }
    return value;
  }

  const copyRowValue = (row) => {
    const {
      title, client, bond, sector, clientsTargetLevel, clientsAmount, comments, direction, currency, size, coupon, ticker, maturity, govSpread, zSpread,
      //yield: yieldValue, // Use yield as yield is a reserved keyword
      cashPrice, isin, } = row.original;

    let key = sidebarModule[2];
    let data = JSON.parse(localStorage.getItem('columnVisibility'))
    let columnVisibility;
    if (type === 'primary') {
      columnVisibility = data[key]['primary']
    } else if (type === 'axes') {
      columnVisibility = data[key]['axes']
    } else if (type === 'general') {
      columnVisibility = data[key]['general']
    } else {
      columnVisibility = data[key]['all']
    }

    // Construct the formatted string

    let formattedValue = '';

    if (isValidValue(title) && columnVisibility.title) formattedValue += `${title}`;


    formattedValue += returnMiddlecopyValue(type, row.original)


    if (isValidValue(comments) && columnVisibility.comments) formattedValue += ` - ${comments}`;

    // Old code
    // if (isValidValue(direction) && columnVisibility.direction) formattedValue += `${direction} `;
    // if (isValidValue(currency) && columnVisibility.currency) formattedValue += `${currency} `;
    // if (isValidValue(size) && columnVisibility.size) formattedValue += `${size} `;
    // if (isValidValue(coupon) && columnVisibility.coupon) formattedValue += `${coupon} `;
    // if (isValidValue(ticker) && columnVisibility.ticker) formattedValue += `${ticker} `;
    // if (isValidValue(maturity) && columnVisibility.maturity) formattedValue += `${maturity} `;
    // if (isValidValue(govSpread) && columnVisibility.govSpread) formattedValue += `Gov+${govSpread} `;
    // if (isValidValue(zSpread) && columnVisibility.zSpread) formattedValue += `Z+${zSpread} `;
    // if (isValidValue(cashPrice) && columnVisibility.cashPrice) formattedValue += `${cashPrice} `;
    // if (isValidValue(isin) && columnVisibility.isin) formattedValue += `${isin}`;

    // Trim any trailing dashes and spaces
    formattedValue = formattedValue.trim();

    // Copy to clipboard logic
    if (navigator?.clipboard && navigator.clipboard.writeText) {
      //console.log("first method");
      navigator.clipboard
        .writeText(formattedValue)
        .then(() => {
          //console.log("Copied to clipboard 1st methos", formattedValue);
          toast.success(text.recordCopied);
        })
        .catch((err) => {
          console.error("Failed to copy:", err);
        });
    } else {
      //console.log("second method");
      // Fallback for older browsers
      const textArea = document.createElement("textarea");
      textArea.value = formattedValue;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand("copy");
        //console.log("Copied to clipboard 2nd method:", formattedValue);
        toast.success(text.recordCopied);
      } catch (err) {
        console.error("Failed to copy:", err);
      }
      document.body.removeChild(textArea);
    }
  };

  const data = useMemo(() => feedbacks, [feedbacks]);

  const feedbackTypeMap = {
    Axes: 'A',
    Primary: 'P',
    General: 'G'
  };

  const bankColumns = [
    {
      id: "select",
      accessorKey: "select",
      header: ({ table }) => (
        <span className="px-1">
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        </span>
      ),
      cell: ({ row }) => (
        <span className="px-1">
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </span>
      ),
      size: 60,
    },
    {
      header: "Copy",
      id: "copy",
      accessorKey: "copy",
      cell: ({ row }) => (
        <div className="px-1">
          <span className="px-4" style={{ cursor: "copy" }}>
            <i
              className="icon-copy fa fa-clone"
              aria-hidden="true"
              onClick={() => copyRowValue(row)}
            ></i>
          </span>
        </div>
      ),
      size: 80,
    },
    {
      header: "Axe Type",
      accessorKey: "axeType",
      id: "axeType",
      cell: (props) => {
        return <DropdownDailyAndAxe {...props} />;
      },
      enableSorting: false,
      enableColumnFilter: true,
    },
    {
      header: "Type",
      accessorKey: "feedbackType",
      id: "feedbackType",
      cell: (props) => {
        return <DropdownFeedbackTypeCell {...props} />;
      },
      enableSorting: false,
      enableColumnFilter: true,
      size: 120,
    },
    /*  {
       header: "Type",
       accessorKey: "feedbackType",
       id: "feedbackType",
       size: 120,
       cell: ({ row }) => {
         //console.log("row.original.feedbackType", row.original.feedbackType);
         const feedbackType = row.original.feedbackType;
         const displayValue = feedbackTypeMap[feedbackType] || feedbackType;
         return <span className="d-flex justify-content-center">{displayValue}</span>;
       },
     }, */
    {
      header: "Title",
      accessorKey: "title",
      id: "title",
    },
    {
      header: "Client",
      accessorKey: "client",
      id: "client",
    },
    {
      header: "Bond",
      accessorKey: "bond",
      id: "bond",
    },
    {
      header: "Sector",
      accessorKey: "sector",
      id: "sector",
    },
    {
      header: "Maturity",
      accessorKey: "maturity",
      id: "maturity",
    },
    {
      header: "Side",
      accessorKey: "direction",
      cell: (props) => {
        return <DropdownCell {...props} />;
      },
      enableSorting: false,
      enableColumnFilter: true,
      id: "direction",
      minSize: 155,
    },
    {
      header: "Interest",
      accessorKey: "interest",
      id: "interest",
      size: 100,
      minSize: 100,
    },
    {
      header: "Client's Target Level",
      accessorKey: "clientsTargetLevel",
      id: "clientsTargetLevel",
      size: 200,
      minSize: 100,
    },
    {
      header: "Client's Amount",
      accessorKey: "clientsAmount",
      id: "clientsAmount",
      size: 180,
      minSize: 100,
    },
    {
      header: "Comment",
      accessorKey: "comments",
      cell: (props) => {
        return <TextAreaCell {...props} />;
      },
      id: "comments",
    },
    {
      header: "Last Update",
      accessorKey: "updatedAt",
      id: "updatedAt",
      cell: ({ row }) => (
        <div className="d-flex justify-content-left">
          {timeZoneChange(row.original.updatedAt, globalContext.timeZoneValue)}
        </div>
      ),
    },
    {
      header: "Created On",
      accessorKey: "createdAt",
      id: "createdAt",
      cell: ({ row }) => (
        <div className="d-flex justify-content-left">
          {timeZoneChange(row.original.createdAt, globalContext.timeZoneValue)}
        </div>
      ),
    },
    {
      id: "action",
      header: "Action",
      accessorKey: "action",
      cell: ({ row }) => (
        <span className='d-flex justify-content-center'>
          <div className="circle cursor" data-toggle="modal" data-target="#alert-modal"
            onClick={() => {
              setShowDeleteModal(true);
              setDeleteId(row.original);
            }}>
            <i className="icon-copy fa fa-trash plus-icon"></i>
          </div>
        </span>
      ),
    },
  ];

  // to persists checkbox changes in filters when users log in/log out
  useEffect(() => {
    const storedVisibility = JSON.parse(localStorage.getItem('columnVisibility'));
    let data;
    if (storedVisibility && storedVisibility.myFeedback) {
      if (type == 'primary') {
        data = storedVisibility.myFeedback['primary']
      } else if (type == 'axes') {
        data = storedVisibility.myFeedback['axes']
      } else if (type == 'general') {
        data = storedVisibility.myFeedback['general']
      } else {
        data = storedVisibility.myFeedback['all']
      }
      setColumnVisibility(data);
      setCheckboxState(data);
    } else {
      initializeColumnVisibility();
    }
  }, [type]);

  const initializeColumnVisibility = () => {
    const initialVisibility = bankColumns.reduce((acc, column) => {
      acc[column.id] = true;
      return acc;
    }, {});
    setColumnVisibility(initialVisibility);
    setCheckboxState(initialVisibility);
    try {
      let key = sidebarModule[2]
      let data = {
        ...JSON.parse(localStorage.getItem('columnVisibility')),
        [key]: {
          all: {},
          primary: {},
          general: {},
          axes: {},
        }
      }

      // if (type == 'primary') {
      data[key]['primary'] = initialVisibility
      // } else if (type == 'axes') {
      data[key]['axes'] = initialVisibility
      // } else if (type == 'general') {
      data[key]['general'] = initialVisibility
      // } else {
      data[key]['all'] = initialVisibility
      // }
      const serializedVisibility = JSON.stringify(data);
      localStorage.setItem('columnVisibility', serializedVisibility);
    } catch (error) {
      console.error('Error serializing column visibility:', error);
    }
  };

  const combinedOnChangeHandler = (column) => (event) => {
    column.getToggleVisibilityHandler()(event);
    setCheckboxState((prevState) => {
      const newState = {
        ...prevState,
        [column.id]: event.target.checked,
      };

      let key = sidebarModule[2]
      let data = JSON.parse(localStorage.getItem('columnVisibility')) || {
        [key]: {
          all: {},
          primary: {},
          general: {},
          axes: {},
        },
      };

      if (type === 'primary') {
        data[key]['primary'][column.id] = event.target.checked;
      } else if (type === 'axes') {
        data[key]['axes'][column.id] = event.target.checked;
      } else if (type === 'general') {
        data[key]['general'][column.id] = event.target.checked;
      } else {
        data[key]['all'][column.id] = event.target.checked;
      }
      localStorage.setItem('columnVisibility', JSON.stringify(data));
      return newState;
    });
  };

  //const columns = useMemo(() => bankColumns, []);
  const columns = useMemo(() => {
    return bankColumns.filter(col => !renderCols.includes(col.accessorKey));
  }, [renderCols]);

  // Exclude columns for certain types of feedback
  useEffect(() => {
    if (location.pathname === '/manageFeedback/primary') {
      setExcCols(['select', 'copy', 'feedbackType', 'updatedAt', 'createdAt', 'axeType']);
      setRenderCols(['axeType', 'feedbackType']);
      //setRenderCols(['select','copy','feedbackType','updatedAt','createdAt','axeType','direction']);
      // ["select","feedbackType", "copy", "updatedAt", "createdAt"]
    } else if (location.pathname === '/manageFeedback/general') {
      setExcCols(['select', 'copy', 'feedbackType', 'updatedAt', 'createdAt', 'axeType']);
      setRenderCols(['axeType', 'feedbackType']);

    } else if (location.pathname === '/manageFeedback') {
      setExcCols(['select', 'copy', 'updatedAt', 'createdAt', 'axeType']);
      setRenderCols(['axeType']);
    }
    else {
      //for Axe type
      setExcCols(['select', 'copy', 'feedbackType', 'updatedAt', 'createdAt', 'axeType']);
      setRenderCols(['axeType', 'feedbackType']);
    }

  }, [location.pathname]);

  const handlePopoverClick = (event) => {
    setTarget(event.target);
    setShowPopover(!showPopover);
  };

  const handleClickOutside = (event) => {
    if (popoverRef.current && !popoverRef.current.contains(event.target)) {
      setShowPopover(false);
    }
  };

  useEffect(() => {
    if (showPopover) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPopover]);

  const handleDelete = () => {
    api
      .deactivateFeedback({ id: deleteId._id })
      .then((res) => {
        if (res.success) {
          toast.success(res.message);
          getfeedbacks(capitalizeFirstLetter(type));
          setShowDeleteModal(false);
          setDeleteId(null);
        }
      })
      .catch((err) => {
        setShowDeleteModal(false);
        setDeleteId(null);
        console.error("Deleting bank error => ", err);
      });
  };

  const updateData = (rowIndex, columnId, value, id) => {
    const updatedRow = { ...feedbacks[rowIndex], [columnId]: value };
    const formData = { ...updatedRow, columnId, value, id }; // Adjust according to your API requirements

    api
      .addEditFeedback(formData)
      .then((res) => {
        if (res.success) {
          toast.success(res.message);
          setFeedbacks((old) =>
            old.map((row, index) => {
              if (index === rowIndex) {
                return updatedRow;
              }
              return row;
            })
          );
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        console.error("Manage fedback error => ", err);
        toast.error("An error occurred while updating the data");
      });
  };

  const handleExport = async () => {
    let selectedRows;
    selectedRows =
      tableInstance && tableInstance.getSelectedRowModel().flatRows;
    let exportData = [];

    const sideTypeMap = {
      weBuy: 'We buy',
      weSell: 'We sell',
      clientBuys: 'Client buys',
      clientSells: 'Client sells'
    };
    if (selectedRows.length > 0) {
      const selectedAxesDatas = selectedRows.map((row) => row.original);
      selectedAxesDatas.forEach((element) => {
        const formattedDirection = sideTypeMap[element.direction] || element.direction; // Map direction value
        exportData.push({
          feedbackType: element.feedbackType,
          title: element.title,
          client: element.client,
          bond: element.bond,
          sector: element.sector,
          direction: formattedDirection, // Use formattedDirection here
          interest: element.interest,
          clientsTargetLevel: element.clientsTargetLevel,
          clientsAmount: element.clientsAmount,
          comments: element.comments,
          updatedAt: element.updatedAt,
          createdAt: element.createdAt,
        });
      });

      /* if (selectedRows.length > 0) {
        const selectedAxesDatas = selectedRows.map((row) => row.original);
        selectedAxesDatas.forEach((element) => {
          exportData.push({
            updatedAt: element.updatedAt,
            createdAt: element.createdAt,
            feedbackType: element.feedbackType,
            title: element.title,
            client: element.client,
            bond: element.bond,
            sector: element.sector,
            direction: element.direction,
            interest: element.interest,
            clientsTargetLevel: element.clientsTargetLevel,
            clientsAmount: element.clientsAmount,
            comments: element.comments,
          });
        }); */
      const headings = [
        [
          "Feedback Type",
          "Title",
          "Client",
          "Bond",
          "Sector",
          "Side",
          "Interest",
          "Clients Target Level",
          "Clients Amount",
          "Comments",
          "Last Update",
          "Created At"
        ],
      ];
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws, headings);
      utils.sheet_add_json(ws, exportData, {
        origin: "A2",
        skipHeader: true,
      });
      utils.book_append_sheet(wb, ws, "Data");
      writeFile(wb, `Feeback Data.xlsx`);
      tableInstance.resetRowSelection();
    }
  };

  const exportPdf = () => {
    const unit = "pt";
    const size = "A1"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);
    const title = "Feedback Data";
    const headers = [
      [
        "Feedback Type",
        "Title",
        "Client",
        "Bond",
        "Sector",
        "Side",
        "Interest",
        "Clients Target Level",
        "Clients Amount",
        "Comments",
        "Last Update",
        "Created At",
      ],
    ];

    let selectedRows =
      tableInstance && tableInstance.getSelectedRowModel().flatRows;
    if (selectedRows.length > 0) {
      const selectedAxesDatas = selectedRows.map((row) => row.original);
      const sideTypeMap = {
        weBuy: 'We buy',
        weSell: 'We sell',
        clientBuys: 'Client buys',
        clientSells: 'Client sells'
      };

      let exportData = selectedAxesDatas.map((element) => {
        const formattedDirection = sideTypeMap[element.direction] || element.direction;
        return [
          element.feedbackType,
          element.title,
          element.client,
          element.bond,
          element.sector,
          formattedDirection, // Use formattedDirection here
          element.interest,
          element.clientsTargetLevel,
          element.clientsAmount,
          element.comments,
          timeZoneChange(element.updatedAt, globalContext.timeZoneValue),
          timeZoneChange(element.createdAt, globalContext.timeZoneValue)
        ];
      });

      let content = {
        head: headers,
        body: exportData,
        startY: 70,
        theme: "grid",
        tableLineColor: [189, 195, 199],
        tableLineWidth: 0.75,
      };
      doc.text(title, marginLeft, 40);
      doc.autoTable(content);
      doc.save(`Feedback Data.pdf`);
      tableInstance.resetRowSelection();
    }
  };

  /* const generateFormattedEmailBody = (type, data) => {

    console.log("type---", type);
    console.log("data---", data);
    

    let lastTitle = ''; // To track the last used title
    let hasPrintedHeader = false; // To ensure the header is printed only once

    return data.map(row => {
      // Split comments by newline
      const commentsList = row.comments ? row.comments.split('\n').filter(comment => comment.trim() !== '') : [];
      // Format comments with bullet points
      const formattedComments = commentsList.length > 0 ? commentsList.map(comment => `        ◦ ${comment.trim()}`).join('\n') : '';

      // Initialize the email body
      let emailBody = '';

      // Print the header text only once
      if (!hasPrintedHeader && type) {
        emailBody += `My Feedback: ${capitalizeFirstLetter(type)}\n`;
        hasPrintedHeader = true;
      }

      // Conditionally add the title if it's different from the last one
      if (row.title && row.title !== lastTitle) {
        emailBody += `\n${row.title}`;
        lastTitle = row.title; // Update the last title
      }

      // Handle fields based on the type
      if (type === 'axes' || type === 'primary') {
        // Show all fields for "axes" type
        if (row.client) emailBody += `\n\n${row.client}`;
        if (row.bond) emailBody += `\n    • Bond/Sector: ${row.bond}`;
        if (row.interest) emailBody += `\n    • Interest: ${row.interest}`;
        if (row.clientsTargetLevel) emailBody += `\n    • Client’s target level: ${row.clientsTargetLevel}`;
        if (row.clientsAmount) emailBody += `\n    • Client’s amount: ${row.clientsAmount}`;
        if (row.maturity) emailBody += `\n    • Maturity: ${row.maturity}`;
        if (formattedComments) emailBody += `\n    • Comment:\n${formattedComments}`;
      } else if (type === 'general') {
        // Show only title, client, and comments for "general" type
        if (row.client) emailBody += `\n\n${row.client}`;
        if (formattedComments) emailBody += `\n    • Comment:\n${formattedComments}`;
      }

      // Trim any unnecessary whitespace at the start and end of the string
      return emailBody.trim();
    }).filter(body => body).join('\n\n'); // Filter out any empty email bodies
  }; */

  /* const generateFormattedEmailBody = (type, data) => {
    let hasPrintedDate = false; // To ensure the date is printed only once
    let groupedData = {}; // Group entries by feedback type
  
    // Group data by feedbackType
    data.forEach(row => {
      if (!groupedData[row.feedbackType]) {
        groupedData[row.feedbackType] = [];
      }
      groupedData[row.feedbackType].push(row);
    });
  
    let emailBody = '';
  
    // Print the date only once
    if (!hasPrintedDate) {
      const currentDate = new Date().toLocaleDateString('en-GB'); // Day/Month/Year format
      emailBody += `${currentDate}:\n\n`;
      hasPrintedDate = true;
    }
  
    // Iterate over each feedbackType group
    for (const [feedbackType, rows] of Object.entries(groupedData)) {
      // Add feedback type header
      emailBody += `Title: ${capitalizeFirstLetter(feedbackType)} Feedback Summary\n`;
  
      // Iterate over each row within the feedback type group
      rows.forEach(row => {
        // Split comments by newline and remove empty lines
        const commentsList = row.comments ? row.comments.split('\n').filter(comment => comment.trim() !== '') : [];
        // Format comments with bullet points
        const formattedComments = commentsList.length > 0 ? commentsList.map(comment => `        ◦ ${comment.trim()}`).join('\n') : '';
  
        // Add the title for each feedback entry
        if (row.title) {
          emailBody += `\n    • Sales-T1:\nTitle (${row.title}):`;
        }
  
        // Handle fields based on feedback type
        if (row.feedbackType === 'Axes' || row.feedbackType === 'Primary') {
          if (row.client) emailBody += `\n        ◦ Client (${row.client})`;
          if (row.bond) emailBody += `\n          ◦ Bond: ${row.bond}`;
          if (row.direction) emailBody += `\n           ◦ Side: ${capitalizeFirstLetter(row.direction.replace('weSell', 'We sell').replace('weBuy', 'We buy'))}`;
          if (row.interest) emailBody += `\n            ◦ Interest: ${row.interest}`;
          if (row.clientsTargetLevel) emailBody += `\n           ◦ Client’s Target Level: ${row.clientsTargetLevel}`;
          if (row.clientsAmount) emailBody += `\n           ◦ Client’s Amount: ${row.clientsAmount}`;
          if (formattedComments) emailBody += `\n    • Comment:\n${formattedComments}`;
        } else if (row.feedbackType === 'General') {
          if (row.client) emailBody += `\n        ◦ Client (${row.client})`;
          if (formattedComments) emailBody += `\n         • Comment:\n${formattedComments}`;
        }
      });
  
      emailBody += '\n\n'; // Add spacing between feedback type groups
    }
  
    return emailBody.trim(); // Return the formatted email body
  }; */

  const generateFormattedEmailBody = (type, data) => {
    let hasPrintedDate = false; // To ensure the date is printed only once
    let groupedData = {}; // Group entries by feedback type

    // Group data by feedbackType
    data.forEach(row => {
      if (!groupedData[row.feedbackType]) {
        groupedData[row.feedbackType] = [];
      }
      groupedData[row.feedbackType].push(row);
    });

    let emailBody = '';

    // Print the date only once
    if (!hasPrintedDate) {
      const currentDate = new Date().toLocaleDateString('en-GB'); // Day/Month/Year format
      emailBody += `${currentDate}:\n\n`;
      hasPrintedDate = true;
    }

    // Iterate over each feedbackType group
    for (const [feedbackType, rows] of Object.entries(groupedData)) {
      // Add feedback type header
      emailBody += `Title: ${capitalizeFirstLetter(feedbackType)} Feedback Summary\n`;

      let salesCounter = 1; // Reset the counter for each feedback type group

      // Iterate over each row within the feedback type group
      rows.forEach(row => {
        // Split comments by newline and remove empty lines
        const commentsList = row.comments ? row.comments.split('\n').filter(comment => comment.trim() !== '') : [];
        // Format comments with bullet points
        const formattedComments = commentsList.length > 0 ? commentsList.map(comment => `              ◦ ${comment.trim()}`).join('\n') : '';

        // Add the title for each feedback entry
        if (row.title) {
          emailBody += `\n    • Sales-T${salesCounter}:\nTitle (${row.title}):`;
          salesCounter++; // Increment the counter for the next entry
        }

        // Handle fields based on feedback type
        if (row.feedbackType === 'Axes' || row.feedbackType === 'Primary') {
          if (row.client) emailBody += `\n        ◦ Client (${row.client})`;
          if (row.bond) emailBody += `\n            ◦ Bond: ${row.bond}`;
          if (row.direction) emailBody += `\n            ◦ Side: ${capitalizeFirstLetter(row.direction.replace('weSell', 'We sell').replace('weBuy', 'We buy'))}`;
          if (row.interest) emailBody += `\n            ◦ Interest: ${row.interest}`;
          if (row.clientsTargetLevel) emailBody += `\n            ◦ Client’s Target Level: ${row.clientsTargetLevel}`;
          if (row.clientsAmount) emailBody += `\n            ◦ Client’s Amount: ${row.clientsAmount}`;
          if (formattedComments) emailBody += `\n            • Comment:\n${formattedComments}`;
        } else if (row.feedbackType === 'General') {
          if (row.client) emailBody += `\n            ◦ Client (${row.client})`;
          if (formattedComments) emailBody += `\n            • Comment:\n${formattedComments}`;
        }
        emailBody += '\n';
      });

      emailBody += '\n\n'; // Add spacing between feedback type groups
    }

    return emailBody.trim(); // Return the formatted email body
  };

  const sendEmail = async () => {
    let selectedRows =
      tableInstance && tableInstance.getSelectedRowModel().flatRows;

    if (selectedRows.length > 0) {
      const selectedData = selectedRows.map((row) => row.original);
      const formattedEmailBody = generateFormattedEmailBody(type, selectedData);

      // Copy formatted email body to clipboard
      try {
        await navigator.clipboard.writeText(formattedEmailBody);
        alert("Email content copied to clipboard! You can now paste it in Gmail.");

        // Open Gmail compose link (without body content)
        const subject = "Selected Table Data";
        const gmailLink = `https://mail.google.com/mail/?view=cm&fs=1&su=${encodeURIComponent(subject)}`;
        window.open(gmailLink, '_blank');
      } catch (error) {
        console.error('Failed to copy email content:', error);
        alert("Failed to copy email content. Please try again.");
      }

      // Reset row selection
      tableInstance.resetRowSelection();
    }
  };


  /* const sendEmail = () => {
    let selectedRows =
      tableInstance && tableInstance.getSelectedRowModel().flatRows;

    if (selectedRows.length > 0) {
      const selectedData = selectedRows.map((row) => row.original);
      const formattedEmailBody = generateFormattedEmailBody(type, selectedData);

      // Prepare the Gmail compose link
      const subject = "Selected Table Data";
      const body = `\n\n${formattedEmailBody}\n\n`;
      const gmailLink = `https://mail.google.com/mail/?view=cm&fs=1&su=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(body)}`;
      window.open(gmailLink, '_blank');
      tableInstance.resetRowSelection();
    }
  }; */

  const selectedColleagues = () => {
    const selectedRows =
      tableInstance && tableInstance.getSelectedRowModel().flatRows;
    if (selectedRows.length > 0) {
      const selectedUserDatas = selectedRows.map((row) => row.original);
      setSelectedCol(selectedUserDatas);
      setAddTocolleagueModal(true);
    }
  };

  const handleKeyDown = (e, accessor) => {

    if (e.key === 'Enter') {
      e.preventDefault();
      if (e.shiftKey) {
        // Shift + Enter pressed
        setNewRow((prevNewRow) => ({
          ...prevNewRow,
          [accessor]: (prevNewRow[accessor] || '') + `\n`
        }));
      }
    }
  };

  const sendType = () => {
    let selectType;
    if (location.pathname === '/manageFeedback') {
      selectType = newRow.feedbackType;
    } else {
      selectType = type && capitalizeFirstLetter(type);
    }
    return selectType;
  }

  const handleSaveClick = () => {
    setSaveBtnStatus(false)
    // Assign cookieData values to newRow
    const updatedNewRow = {
      ...newRow,
      associatedWith: cookieData.AssociatedWith,
      createdBy: cookieData._id,
      feedbackType: sendType(),
    };

    //console.log("updatedNewRow", updatedNewRow);

    // Check if any other field has a value
    const hasOtherFields = Object.keys(updatedNewRow).some(
      (key) =>
        !["associatedWith", "createdBy"].includes(key) && updatedNewRow[key]
    );

    if (!hasOtherFields) {
      //toast.error('Please fill in at least one field.');
      return;
    }
    // Validation
    const newErrors = {};
    /* if (!updatedNewRow.name) newErrors.name = 'Name is required';
    if (!updatedNewRow.age) newErrors.age = 'Age is required';
    if (!updatedNewRow.country) newErrors.country = 'Country is required'; */
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      api
        .addEditFeedback(updatedNewRow)
        .then((res) => {
          if (res.success) {
            if (textAreaRef.current) {
              textAreaRef.current.style.height = 'auto';
            }
            setNewRow({});
            getfeedbacks(capitalizeFirstLetter(type));
            toast.success(res.message);
            setSaveBtnStatus(true)
          } else {
            toast.error(res.message);
            setErrors({});
            setSaveBtnStatus(true)
          }
        })
        .catch((err) => {
          console.error("Add bank error => ", err);
          setErrors({});
          setSaveBtnStatus(true)
        });
    }
  };

  const handleChange2 = (e, accessor) => {
    if (!e || !e.target) {
      return;
    }
    setNewRow({
      ...newRow,
      [accessor]: e.target.value,
    });
  };

  const handleKeyDown2 = (e, accessor) => {
    if (e.key === "Enter" && accessor) {
      handleSaveClick(e, accessor);
    }
  };

  const onBlur = (e, accessor) => {
    if (accessor) {
      handleSaveClick(e, accessor);
    }
  };

  const adjustHeight = (textarea) => {
    textarea.style.height = 'auto';
    textarea.style.height = textarea.scrollHeight + 'px';
  };

  const handleInput = (event) => {
    const textarea = event.target;
    adjustHeight(textarea);
    if (textarea.value === '') {
      textarea.style.height = 'auto'; // Reset to normal height when empty
    }
  };

  /* const extractFieldsFromSentence = (sentence) => {

    // Match the title starting with 'T' and ending with the first period
    const titleMatch = sentence.match(/^T.*?[.:?]/);
    const title = titleMatch[0].replace('T', '').trim();
    let remainingSentence = sentence;

    // If a title was found, remove it from the remaining sentence to process other parts
    if (titleMatch) {
      remainingSentence = remainingSentence.replace(titleMatch[0], '').trim();
    }

    // Match the client name after the type
    const clientMatch =  remainingSentence.match(/\b(A|P|G)\b\s+([\w\s]+?)(?=\s+(is\s+|interested|interest|expects|cares|sold|no\s+cares|no\s+interest|not\s+interested|not\s+expects|does\s+not|do\s+not))/i);

    // Bond match: handle ISIN and extended formats
    const bondMatch = sentence.match(/mio\s+([\d\w\s]+?)(?=\s+Gov|\s+Z|\s+Yield|:)/);

    // Direction match and mapping
    const directionMatch = title.match(/(We\s+sell|client\s+buys|We\s+buy|client\s+sells|\/\d+)/i);
    const directionMapping = {
      'We sell': 'weSell',
      'client buys': 'clientBuys',
      'We buy': 'weBuy',
      'client sells': 'clientSells',
      '/': 'unknown', // Handle cases with slashes if needed
    };

    // Spread, ISIN, amount, interest matches
    const spreadMatch = remainingSentence.match(/(?:Z\+\d+|Gov\+\d+|T\+\d+|B\+\d+|FR\+\d+|\d+\/|\d+\/\d+)/i);
    const amountMatch = remainingSentence.match(/(\d+\s?(mio|m|mm|k))/i);
    const interestYesMatch = remainingSentence.match(/\b(interested|interest|cares|sold|do have it|does have it|do own|does own)\b/i);
    const interestNoMatch = remainingSentence.match(/\b(no cares|no interest|not interested|do not have it|does not have it|does not|do not|do not own|does not own)\b/i);

    const typeMapping = {
      A: 'Axes',
      P: 'Primary',
      G: 'General'
    };

      return {
        title: titleMatch ? title : '',
        type: clientMatch ? typeMapping[clientMatch[1]] : '',
        client: clientMatch ? clientMatch[2] : '',
        bond: bondMatch ? bondMatch[1] : '',
        spread: spreadMatch ? spreadMatch[0] : '',
        clientsAmount: amountMatch ? amountMatch[0] : '',
        direction: directionMatch ? directionMapping[directionMatch[0]] || 'unknown' : '',
        interest: interestNoMatch ? 'no' : interestYesMatch ? 'yes' : ''
      };
  }; */

  // Function to extract client
  function extractClient(sentence, typeRegex, noTypeRegex) {
    let clientMatch;

    console.log("sentence====",sentence);
    

    // Check if the sentence starts with a type (A, P, G)
    if (/^[A|P|G]\b/.test(sentence)) {
      clientMatch = sentence.match(typeRegex);
    } else {
      // Otherwise match without type prefix
      clientMatch = sentence.match(noTypeRegex);
    }

    console.log("clientMatch==", clientMatch);


    // Return the client if matched, otherwise 'No client found'
    return clientMatch ? clientMatch[1].trim() : 'No client found';
  }

  // Function to extract type
  function extractType(sentence, typeRegex) {
    const typeMatch = sentence.match(typeRegex);

    return typeMatch ? typeMatch[0].trim() : 'A';
  }

  // Function to check interest
  function extractInterest(sentence, interestYesRegex, interestNoRegex) {
    const interestNoMatch = sentence.match(interestNoRegex);
    const interestYesMatch = sentence.match(interestYesRegex);

    if (interestNoMatch) {
      return `no`;
    } else if (interestYesMatch) {
      return `yes`;
    } else {
      return "";
    }
  }

  // Function to determine the side if no direction match is found
  const checkForSlashNumbers = (title) => {
    // Check for `/number` at the end of the string (weSell case)
    const weSellMatch = title.match(/\/\d+$/);
    // Check for `number/` at the end of the string (weBuy case)
    const weBuyMatch = title.match(/\d+\/$/);

    if (weSellMatch) {
      return 'weSell';
    }
    if (weBuyMatch) {
      return 'weBuy';
    }
    return 'unknown'; // Default if no match is found
  };

  // Function to check Maturity
  const extractMaturity = (sentence) => {
    // Ensure the input is a string
    if (typeof sentence !== 'string') {
      console.error('Input is not a string:', sentence);
      return ''; // Return an empty string if the input is not valid
    }
  
    // Match two-digit year following ticker
    const maturityMatch = sentence.match(/\b[A-Z]{2,5}\s(\d{2})\b/); // Matches two digits after ticker
    
    // Add '20' prefix if match is found
    return maturityMatch ? `20${maturityMatch[1]}` : '';
  };

  const extractFieldsFromSentence = (sentence) => {

    let title = '';
    let remainingSentence = sentence;

    // Match the title if it starts with 'T' and ends with a period, colon, or question mark
    const titleMatchWithT = sentence.match(/^T.*?[.:?]/);

    if (titleMatchWithT) {
      title = titleMatchWithT[0]
        .replace('T', '')             // Remove 'T'
        .replace(/[.:?]$/, '')        // Remove ending punctuation (. : ?)
        .trim();                      // Trim any whitespace
      remainingSentence = remainingSentence.replace(titleMatchWithT[0], '').trim(); // Remove title from sentence
    } else {
      // Match the first sentence that ends with a period, colon, or question mark (without 'T')
      const titleMatchWithoutT = sentence.match(/^[^.?:]+[.:?]/);
      if (titleMatchWithoutT) {
        title = titleMatchWithoutT[0]
          .replace(/[.:?]$/, '')      // Remove ending punctuation (. : ?)
          .trim();                    // Trim any whitespace
        remainingSentence = remainingSentence.replace(titleMatchWithoutT[0], '').trim(); // Remove the extracted part
      }
    }

    // Match the client name after the type
    const typeRegex = /^[A|P|G]\s+([\w\s\/]+?)(?=\s*,?\s*(is\s+|interested|interest|expects|cares|sold|no\s+cares|no\s+interest|not\s+interested|not\s+expects|does\s+not|do\s+not|are))/i;

    // Regex to extract the client without type prefix
    const noTypeRegex = /^([\w\s\/]+?)(?=\s*,?\s*(is\s+|interested|interest|expects|cares|sold|no\s+cares|no\s+interest|not\s+interested|not\s+expects|does\s+not|do\s+not|are))/i;

    const clientMatch = extractClient(remainingSentence, typeRegex, noTypeRegex);

    // Regex to extract type prefix (A, P, G)
    const typeCheck = /^[A|P|G]\b/i;
    const type = extractType(remainingSentence, typeCheck);

    // Bond match: handle ISIN and extended formats
    const bondMatch = sentence.match(/mio\s+([\d\w\s]+?)(?=\s+Gov|\s+Z|\s+Yield|:)/);

    // Maturity extract
    const MaturityMatch = extractMaturity(bondMatch[1]);

    // Direction match and mapping
    const directionMatch = title.match(/\b(We\s+sell|sell|offer|offers|client\s+buys|client\s+sells|buy|bid|bids|\/\d+)\b/i);

    const directionMapping = {
      'We sell': 'weSell',
      'sell': 'weSell',
      'offer': 'weSell',
      'offers': 'weSell',
      'client buys': 'clientBuys',
      'We buy': 'weBuy',
      'buy': 'weBuy',
      'bid': 'weBuy',
      'bids': 'weBuy',
      'client sells': 'clientSells',
      '/': 'unknown', // Handle cases with slashes if needed
    };

    let side;
    if (directionMatch) {
      side = directionMapping[directionMatch[0]] || 'unknown';
    } else {
      side = checkForSlashNumbers(title);
    }

    // Spread, ISIN, amount, interest matches
    const spreadMatch = remainingSentence.match(/(?:Z\+\d+|Gov\+\d+|T\+\d+|B\+\d+|FR\+\d+|\d+\/|\d+\/\d+)/i);

    const amountMatch = remainingSentence.match(/(\d+\s?(mio|m|mm|k))/i);

    // Regex to match expressions indicating interest
    const interestYesRegex = /\b(is interested|are interested|interested|has interest|have interest|interest|cares|sold|bought|would care|do have it|does have it|do own|does own)\b/i;

    // Regex to match expressions indicating no interest
    const interestNoRegex = /\b(not interested|has no cares|have no cares|no cares|does (not|n't) care|do (not|n't) care|has no interest|have no interest|is not interested|no interest|is not interested at that level|are not interested at that level|not interested at that level|is not interested at that spread|are not interested at that spread|not interested at that spread|does (not|n't) have it|do (not|n't) have it|does (not|n't) own|do (not|n't) own)\b/i;

    const interest = extractInterest(sentence, interestYesRegex, interestNoRegex);

    const typeMapping = {
      A: 'Axes',
      P: 'Primary',
      G: 'General'
    };

    // Improved comment extraction
    const commentMatch = remainingSentence.match(/(?:Would care.*?\.\s+|^|\s)(In general.*|full in that name|too tight|prefers.*|expects.*|does not have it|too wide.*)$/i);

    return {
      title: title, // Title found or empty string
      type: directionMatch ? 'Axes' : (type ? typeMapping[type] : ''),
      client: clientMatch,
      bond: bondMatch ? bondMatch[1] : '',
      spread: spreadMatch ? spreadMatch[0] : '',
      clientsAmount: amountMatch ? amountMatch[0] : '',
      direction: side,
      interest: interest,
      comment: commentMatch ? commentMatch[1].trim() : '',
      maturity: MaturityMatch
    };
  };

  const handlePaste = (e) => {

    try {
      const pastedText = e.clipboardData.getData('text');
      const extractedFields = extractFieldsFromSentence(pastedText);

      console.log("extractedFields", extractedFields);

      // Collect fields to exclude
      const fieldsToExclude = [
        extractedFields.title,
        extractedFields.type,
        extractedFields.client,
        extractedFields.bond,
        extractedFields.clientsAmount,
        extractedFields.clientsTargetLevel,
        extractedFields.direction,
        extractedFields.interest,
        extractedFields.maturity,
        extractedFields.comment
      ].filter(Boolean); // Filter out undefined or empty values

      // Update state with extracted fields and remaining comments
      setTimeout(() => {
        setNewRow(prevRow => {
          // Ensure prevRow is in the correct scope
          return {
            ...prevRow,
            title: extractedFields.title || prevRow.title,
            feedbackType: extractedFields.type || prevRow.type,
            client: extractedFields.client || prevRow.client,
            clientsTargetLevel: extractedFields.spread || prevRow.spread,
            bond: extractedFields.bond || prevRow.bond,
            clientsAmount: extractedFields.clientsAmount || prevRow.clientsAmount,
            direction: extractedFields.direction || prevRow.direction,
            interest: extractedFields.interest || prevRow.interest,
            maturity: extractedFields.maturity || prevRow.maturity,
            comments: extractedFields.comment || prevRow.comment, // Update the comments section with the remaining text
          };
        });
      }, 200);

    } catch (error) {
      console.error('Error handling paste:', error);
    }
  };

  return (
    <>
      {showDeleteModal && <DeleteModal handleDelete={handleDelete} />}
      {addTocolleagueModal && (
        <AddtoCollaegueModal
          setAddTocolleagueModal={setAddTocolleagueModal}
          selectedList={selectedCol}
          tableInstance={tableInstance}
        />
      )}

      <div className="mb-10">
        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-4">
            <h4 className="text-black h3 mb-3">{type == "manageFeedback" ? "My" : type} {text.Feedback}</h4>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-8">
            <div className="mb-3 mb-sm-3">

              {/* {dailyTopAxesData?.length > 0 && ( */}
              <div className="row justify-content-between">

                <div className="col-2">

                  <div className="d-flex justify-content-md-center justify-content-lg-end d-md-none d-sm-block">
                    <i
                      className="icon-copy fa fa-eye-slash pr-20 cursor bg-custom-eye rounded-circle p-2 mr-4"
                      aria-hidden="true"
                      onClick={handlePopoverClick}
                    ></i>
                    <Overlay
                      show={showPopover}
                      target={target}
                      placement="bottom"
                      containerPadding={20}
                      className="setOverlay"
                      ref={popoverRef}
                    >
                      <Popover id="popover-basic">
                        <Popover.Body >
                          <div className="inline-block  rounded">
                            <div className="d-flex align-items-center p-1 left-margin">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="10 0 512 512" width="20" height="20"><path d="M152.1 38.2c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 113C-2.3 103.6-2.3 88.4 7 79s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zm0 160c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 273c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zM224 96c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zM160 416c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H192c-17.7 0-32-14.3-32-32zM48 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" /></svg>
                              <span className="ml-2"><b>{text.filterColumn}</b> </span>
                            </div>
                            <div className="row p-2">
                              {tableInstance && tableInstance.getAllLeafColumns().filter(column => column.id !== 'action' && column.id !== 'select').map(column => {
                                return (
                                  <div key={column.id} className="col-6 px-1">
                                    <label className="cursor">
                                      <input
                                        {...{
                                          type: 'checkbox',
                                          checked: checkboxState[column.id] !== undefined ? checkboxState[column.id] : column.getIsVisible(),
                                          onChange: combinedOnChangeHandler(column),
                                        }}
                                      />{' '}
                                      {column.columnDef.header}
                                    </label>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </Popover.Body>
                      </Popover>
                    </Overlay>
                  </div>

                </div>
                <div className="col-10">
                  <div className="d-flex flex-wrap flex-md-nowrap justify-content-end">
                    <span className="d-none d-md-block">
                      <i
                        className="icon-copy fa fa-eye-slash pr-20 cursor bg-custom-eye rounded-circle p-2 mr-4"
                        aria-hidden="true"
                        onClick={handlePopoverClick}
                      ></i>
                      <Overlay
                        show={showPopover}
                        target={target}
                        placement="bottom"
                        containerPadding={20}
                        className="setOverlay"
                        ref={popoverRef}
                      >
                        <Popover id="popover-basic">
                          <Popover.Body>
                            <div className="inline-block  rounded">
                              <div className="d-flex align-items-center p-1 left-margin">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="10 0 512 512"
                                  width="20"
                                  height="20"
                                >
                                  <path d="M152.1 38.2c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 113C-2.3 103.6-2.3 88.4 7 79s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zm0 160c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 273c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zM224 96c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zM160 416c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H192c-17.7 0-32-14.3-32-32zM48 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
                                </svg>
                                <span className="ml-2">
                                  <b>{text.filterColumn}</b>{" "}
                                </span>
                              </div>
                              <div className="row p-2">
                                {tableInstance &&
                                  tableInstance
                                    .getAllLeafColumns()
                                    .filter(
                                      (column) =>
                                        column.id !== "action" &&
                                        column.id !== "select"
                                    )
                                    .map((column) => {
                                      return (
                                        <div key={column.id} className="col-6 px-1">
                                          <label className="cursor">
                                            <input
                                              {...{
                                                type: "checkbox",
                                                checked:
                                                  checkboxState[column.id] !==
                                                    undefined
                                                    ? checkboxState[column.id]
                                                    : column.getIsVisible(),
                                                onChange:
                                                  combinedOnChangeHandler(column),
                                              }}
                                            />{" "}
                                            {column.columnDef.header}
                                          </label>
                                        </div>
                                      );
                                    })}
                              </div>
                            </div>
                          </Popover.Body>
                        </Popover>
                      </Overlay>
                    </span>
                    <span>
                      <button
                        type="button"
                        className="btn btn-grey mr-md-4 w-auto mt-2 mr-2 mt-md-0"
                        onClick={() => sendEmail()}
                      >
                        <i className="icon-copy fi-mail" aria-hidden="true" />{" "}
                        {text.sendEmail}
                      </button>
                    </span>
                    <span>
                      <button
                        type="button"
                        className="btn btn-grey mr-md-4 w-auto mt-2 mr-2 mt-md-0"
                        onClick={() => handleExport()}
                      >
                        <i
                          className="icon-copy fa fa-file-excel-o"
                          aria-hidden="true"
                          style={{ color: "green" }}
                        />{" "}
                        {text.exportExcel}
                      </button>
                    </span>
                    <span>
                      <button
                        type="button"
                        className="btn btn-grey mr-md-4 w-auto mt-2 mr-2 mt-md-0"
                        onClick={() => exportPdf()}
                      >
                        <i
                          className="icon-copy fa fa-file-pdf-o"
                          aria-hidden="true"
                          style={{ color: "red" }}
                        />{" "}
                        {text.exportPDF}
                      </button>
                    </span>
                    <span>
                      <button
                        type="button"
                        className="btn btn-grey mt-2 mr-2 mt-md-0"
                        data-toggle="modal"
                        data-target="#alert-modal1"
                        onClick={() => {
                          selectedColleagues();
                        }}
                      >
                        <i className="micon icon-copy fa fa-users" /> Send to
                      </button>
                    </span>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="card-box">
          <DataTableEditable
            data={data}
            columns={columns}
            //columns={columns.filter((column) => !excCols.includes(column.id))}
            columnVisibility={columnVisibility}
            setColumnVisibility={setColumnVisibility}
            setTableInstance={setTableInstance}
            updateData={updateData}
            isLoading={isLoading}
            handleSaveClick={handleSaveClick}
            handleChange2={handleChange2}
            addingRow={true}
            newRow={newRow}
            //excCols={['select', 'feedbackType', 'copy', 'updatedAt', 'createdAt']}
            excCols={excCols}
            handleKeyDown2={handleKeyDown2}
            handleKeyDown={handleKeyDown}
            onBlur={onBlur}
            handleInput={handleInput}
            textAreaRef={textAreaRef}
            saveBtnStatus={saveBtnStatus}
            setNewRow={setNewRow}
            handlePaste={handlePaste}
          //location={location}  // Pass location as a prop
          />
        </div>
      </div>
    </>
  );
};

export default Index;
