import BaseApi from "./baseApi";

class AxeApi extends BaseApi {

	async addEditAxe(data) {
		return await this.API({
			url: "/axe/addAxe",
			data: data,
			method: "post"
		})
	}

	async getAxes(data) {
		return await this.API({
			url: "/axe/getAxes",
			data: data,
			method: "post"
		})
	}

	 async getAxeById(data) {
		return await this.API({
			url: "/axe/getAxeById",
			data: data,
			method: "post"
		})
	}
	async deactivateAxe(data) {
		return await this.API({
			url: "/axe/deactivateAxeById",
			data: data,
			method: "post"
		})
	}

	async addToAxesType(data) {
		return await this.API({
			url: "/axe/addToAxesType",
			data: data,
			method: "post"
		})
	}

	async addToFeedback(data) {
		return await this.API({
			url: "/axe/addToFeedback",
			data: data,
			method: "post"
		})
	}

}

export default AxeApi;