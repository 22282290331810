import AuthState from './context/auth/authState';
import IndexRouter from './routers';
function App() {

  return (
    <>
      <AuthState>
        <IndexRouter />
      </AuthState>
    </>
  );
}

export default App;
