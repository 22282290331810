import moment from "moment-timezone";

const timeFormat = 'DD.MM.YYYY hh:mmA'
const dateFormat = 'DD.MM.YYYY';
export const bankAdminRole = 'Bank Admin';

const roles = ['sales', 'syndicate', 'trader',];

export const sidebarModule = ['axeMgnt', 'axeTool', 'myFeedback', 'collegueFeedback', 'teamMember']

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const timeZoneChange = (value, timezone) => {
  return moment(value).tz(timezone).format(timeFormat)
};

export const formatDate = (value, timezone) => {
  return moment(value).tz(timezone).format(dateFormat);
};

const typeMapping = {
  A: 'Axes',
  P: 'Primary',
  G: 'General'
};

export default roles;