import BaseApi from "./baseApi";

class BankApi extends BaseApi {

	async addEditBank(data) {
		return await this.API({
			url: "/bank/addBank",
			data: data,
			method: "post"
		})
	}

	async getBanks() {
		return await this.API({
			url: "/bank/getBanks",
			method: "get"
		})
	}

	async getBankById(data) {
		return await this.API({
			url: "/bank/getBankById",
			data: data,
			method: "post"
		})
	}

	async deleteBank(data) {
		return await this.API({
			url: "/bank/deleteBank",
			data: data,
			method: "post"
		})
	}

	async activatDeacivateBank(data) {
		return await this.API({
			url: "/bank/activatDeacivateBank",
			data: data,
			method: "post"
		})
	}

}

export default BankApi;