
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useEffect, useState } from 'react'
import text from '../../util/text'
import { Loader } from '../Loader';

const DataTable = ({ data, columns, columnVisibility, setColumnVisibility, setTableInstance, isLoading }) => {
  const [sorting, setSorting] = useState([])
  const [filtering, setFiltering] = useState('');

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting: sorting,
      globalFilter: filtering,
      columnVisibility,
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    onGlobalFilterChange: setFiltering,
    onColumnVisibilityChange: setColumnVisibility,
    columnResizeMode: 'onChange',
  })

  // Pass the table instance to the parent component
  useEffect(() => {
    setTableInstance(table);
  }, [table, setTableInstance]);


  return (
    <>
      <div className='row pt-20 px-4'>
        <div className='col-12'>
          {/* search bar */}
          <input
            type='text'
            className='form-control mb-3'
            value={filtering}
            onChange={e => setFiltering(e.target.value)}
            placeholder='Search...'
          />
          {
            isLoading ? <Loader /> :
              !isLoading && data.length == 0 ? (
                <div className="row d-flex align-content-center justify-content-center py-5">
                  <h5>{text.noDataTable}</h5>
                </div>
              ) : (
                <>
                  <div className='table-responsive'>
                    <table className='table table-custom' style={{ width: table.getTotalSize() }}>
                      <thead className='thead-light'>
                        {table.getHeaderGroups().map(headerGroup => (
                          <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                              <th
                                key={header.id}
                                onClick={header.column.getToggleSortingHandler()}
                                style={{ cursor: 'pointer', width: header.getSize() }}
                              >
                                {header.isPlaceholder ? null : (
                                  <div>
                                    {flexRender(
                                      header.column.columnDef.header,
                                      header.getContext()
                                    )}

                                    {
                                      { asc: <span className="icon-copy ti-arrow-up ml-2"></span>, desc: <span className="icon-copy ti-arrow-down ml-2"></span> }[
                                      header.column.getIsSorted() ?? null
                                      ]
                                    }
                                  </div>
                                )}
                                <div className='resizer' onMouseDown={header.getResizeHandler()} onTouchStart={header.getResizeHandler()}></div>
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>

                      <tbody>
                        {table.getRowModel().rows.map(row => (
                          <tr key={row.id}>
                            {row.getVisibleCells().map(cell => (
                              <td key={cell.id} style={{ width: cell.column.getSize(), }}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  {/* pagination */}
                  <div className='d-flex flex-wrap justify-content-center justify-content-md-end align-content-center mt-3 mb-3'>
                    <div className="mr-2 font-weight-bold align-content-center">
                      <span className='mr-2'>Page</span>
                      {table.getState().pagination.pageIndex + 1} of{' '}
                      {table.getPageCount().toLocaleString()}
                    </div>
                    <div className="ml-2 font-weight-bold">
                      <span>{text.goToPage}</span>
                      <input
                        type="number"
                        min="1"
                        defaultValue={table.getState().pagination.pageIndex + 1}
                        onChange={e => {
                          const page = e.target.value ? Number(e.target.value) - 1 : 0
                          table.setPageIndex(page)
                        }}
                        className="ml-2 border p-1 rounded width-paginatiion-input"
                      />
                    </div>
                    <select
                      value={table.getState().pagination.pageSize}
                      onChange={e => {
                        table.setPageSize(Number(e.target.value))
                      }}
                      className='ml-2 border rounded mr-2'
                    >
                      {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                          {text.pageSize} {pageSize}
                        </option>
                      ))}
                    </select>
                    <button className='mr-4 no-bg' onClick={() => table.setPageIndex(0)}>{'<-'}</button>
                    <button className='no-bg mr-2' disabled={!table.getCanPreviousPage()} onClick={() => table.previousPage()} >{'<'} </button>
                    <button className='no-bg' disabled={!table.getCanNextPage()} onClick={() => table.nextPage()}>{'>'}</button>
                    <button className='ml-4 no-bg' onClick={() => table.setPageIndex(table.getPageCount() - 1)}>{'->'}</button>
                  </div>
                </>
              )
          }
        </div>
      </div>
    </>
  )
};

export default DataTable;
