import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BankApi from "../../../api/bankApi";
import { toast } from "react-toastify";
import text from "../../../util/text";

const AddBank = () => {
  const { id } = useParams();
  const api = new BankApi();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    _id: '',
    bankName: "",
    bankManagerName: "",
    email: "",
    phone: "",
  });
  const [errors, setErrors] = useState({});
  const [submitBtnStatus, setSubmitBtnStatus] = useState(false)

  useEffect(() => {
    if (id) {
      api.getBankById({ id: id }).then((res) => {
        if (res.success) {
          const { _id, bankName, bankManagerName, email, phone } = res.data;
          setFormData({ _id, bankName, bankManagerName, email, phone });
        } else {
          toast.error(res.message);
        }
      }).catch((err) => {
        console.error("Fetch bank by ID error => ", err);
      });
    }
  }, [id]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });

    // Clear errors for the specific field
    const newErrors = { ...errors };
    if (value.trim() !== "") {
      delete newErrors[name];
    }

    // Additional validation for email
    if (name === "email" && value.trim() !== "") {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
        newErrors.email = "Invalid email address";
      } else {
        delete newErrors.email;
      }
    }

    // if (name === "phone" && value.trim() !== "") {
    //   if (value.length !== 10) {
    //     newErrors.phone = "Phone number must be exactly 10 digits";
    //   } else {
    //     delete newErrors.phone;
    //   }
    // }

    setErrors(newErrors);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newErrors = {};
    if (!formData.bankName) {
      newErrors.bankName = "Bank name is required";
    }
    if (!formData.bankManagerName) {
      newErrors.bankManagerName = "Bank manager name is required";
    }
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)
    ) {
      newErrors.email = "Invalid email address";
    }
    // if (!formData.phone) {
    //   newErrors.phone = "Phone number is required";
    // } else 
    // if (formData.phone.length !== 10) {
    //   newErrors.phone = "Phone number must be exactly 10 digits";
    // }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setSubmitBtnStatus(true)
      api.addEditBank(formData).then((res) => {
        if (res.success) {
          toast.success(res.message);
          navigate("/manageBanks");
          setSubmitBtnStatus(false)
        } else {
          toast.error(res.message);
          setErrors({});
          setSubmitBtnStatus(false)
        }
      }).catch((err) => {
        console.error("Add bank error => ", err);
        setErrors({});
        setSubmitBtnStatus(false)
      });
    }
  };

  return (
    <>
      <div className="min-height-200px">
        <div className="pd-20 d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
          <h5 className="text-black mb-3 mb-md-0">{text.addUpdateHeader}</h5>
          <span className="text-black mb-3 mb-md-0 cursor" onClick={() => navigate(-1)}>
            <span className="icon-copy ti-angle-left" />
            {text.backText}
          </span>
        </div>
        <div className="card-box mb-30">
          <div className="pd-20 d-flex">
            <div className="container">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="bankName">{text.bankName}</label>
                      <input
                        type="text"
                        className="form-control"
                        id="bankName"
                        name="bankName"
                        value={formData.bankName}
                        onChange={handleChange}
                        placeholder={text.bankName}
                      />
                      {errors.bankName && (
                        <div className="text-danger">
                          {errors.bankName}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="bankManagerName">
                        {text.bankAdminName}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="bankManagerName"
                        name="bankManagerName"
                        value={formData.bankManagerName}
                        onChange={handleChange}
                        placeholder={text.bankAdminName}
                      />
                      {errors.bankManagerName && (
                        <div className="text-danger">
                          {errors.bankManagerName}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="email">{text.email}</label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder={text.email}
                      />
                      {errors.email && (
                        <div className="text-danger">
                          {errors.email}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="phone">{text.phone}</label>
                      <input
                        type="tel"
                        className="form-control"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        onInput={(e) => e.target.value = e.target.value.replace(/\D/, '')}
                        placeholder={text.phone}
                        // minLength={10}
                        // maxLength={10}
                      />
                      {errors.phone && (
                        <div className="text-danger">
                          {errors.phone}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-secondary mr-4"
                    onClick={() => navigate(-1)}
                  >
                    {text.cancel}
                  </button>
                  <button type="submit" className="btn btn-orange" 
                    disabled={submitBtnStatus}>
                    {id && id !== '' ? text.updateBank : text.addBank}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddBank;
