import axios from "axios";
import { useCookies, Cookies } from "react-cookie";
const API_URL = process.env.REACT_APP_API_URL;
class BaseApi {
  //, additionalHeaders = {}
  API = async (options, onUploadPrgress, onDownloadPrgress) => {
    const cookies = new Cookies();
    let token = cookies.get('token') || null;
    let authHeader = "";
    if (token) {
      authHeader = `Bearer ${token}`;
    }

    const client = axios.create({
      baseURL: API_URL,
      headers: { authorization: authHeader },//, ...additionalHeaders
      onUploadProgress: onUploadPrgress,
      onDownloadProgress: onDownloadPrgress,
    });

    return client(options).then((response) => {
      if (response.data && response.data.error && response.data.code === 22) {
        window.location.href = "/logout"
      } else {
        return response.data;
      }
    }).catch((error) => {
      console.log("Network Error", error);
    });
  };
}

export default BaseApi;
