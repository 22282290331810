import BaseApi from "./baseApi";

class NotificationApi extends BaseApi {

	async getUserNotification(userId,notificationsCount) {
		return await this.API({
			url: `/notification/user-notifications?userId=${userId}&count=${notificationsCount}`,
			method: "get"
		});
	}

	 async sendNotificationDecision(data) {
		console.log("data",data);
		
		return await this.API({
			url: "/notification/send-notification-decision",
			data: data,
			method: "post"
		});
	}

	async cancelNotificationRequest(data) {
		console.log("data",data);
		
		return await this.API({
			url: "/notification/cancel-notification",
			data: data,
			method: "post"
		});
	}

	

}

export default NotificationApi;